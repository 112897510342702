import React from "react";
import Footer from '../partial/footer';

function Blogone(){
    return(
        <div>
            <div class="container">
                <h4>Virdi Nigeria’s Time Office Software: Efficient Web and Window Based Solution</h4><br/>
                <h6 class="link1"><b>VIRDI Nigeria's</b> time office software is an efficient web and window-based solution 
                    designed to streamline time and attendance management processes. This software offers 
                    several benefits that contribute to its efficiency:
                </h6>
                <h6>1. It has a user-friendly interface that makes it easy for users/ Employees to use without stress.</h6>
                <h6>2. It is both web and windows integrated for users to choose their preferred option while using.</h6>
                <h6>3. It has Time and Attendance features that allows users to use various clock in methods such as biometric 
                    devices, RFID cards, or PINs.
                </h6>
                <h6>4. It keeps track of employees leave and absent allowances, and also allows them request for leave waiting 
                    for the HR to approve.
                </h6>
                <h6>5. It generates a comprehensive report and analysis that relates to data and attendance.</h6>
                <h6 class="link2">By implementing VIRDI Nigeria's time office software, organizations can optimize time and attendance management, 
                    improve scheduling efficiency, reduce administrative workload, enhance data accuracy, and gain valuable insights 
                    through comprehensive reporting and analytics.
                </h6>
            </div>
            <Footer/>
        </div>
    )
}
export default Blogone;