import Footer from "../partial/footer";
import NavBar from "../partial/navbar";


function Iris() {

    return (
        <div>
            <NavBar />

            <div id="content" class="site-content">
                <div class="ast-container">
                    <div id="primary" class="content-area primary">
                        <main id="main" class="site-main">
                            <article class="post-2355 page type-page status-publish ast-article-single"
                                id="post-2355" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                                <header class="entry-header ast-header-without-markup">
                                </header>
                                <div
                                    class="entry-content
                                        clear"
                                    itemprop="text">
                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id="2355"
                                        class="elementor
                                            elementor-2355">
                                        <div
                                            class="elementor-inner">
                                            <div
                                                class="elementor-section-wrap">
                                                <section
                                                    class="elementor-section
                                                        elementor-top-section
                                                        elementor-element
                                                        elementor-element-2eb2533
                                                        elementor-section-boxed
                                                        elementor-section-height-default
                                                        elementor-section-height-default"
                                                    data-id="2eb2533"
                                                    data-element_type="section"
                                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                    <div
                                                        class="elementor-container
                                                            elementor-column-gap-default">
                                                        <div
                                                            class="elementor-row">
                                                            <div
                                                                class="elementor-column
                                                                    elementor-col-100
                                                                    elementor-top-column
                                                                    elementor-element
                                                                    elementor-element-dc59f31"
                                                                data-id="dc59f31"
                                                                data-element_type="column">
                                                                <div
                                                                    class="elementor-column-wrap
                                                                        elementor-element-populated">
                                                                    <div
                                                                        class="elementor-widget-wrap">
                                                                        <div
                                                                            class="elementor-element
elementor-element-881e12b
elementor-widget
elementor-widget-image"
                                                                            data-id="881e12b"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default">
                                                                            <div
                                                                                class="elementor-widget-container">
                                                                                <div
                                                                                    class="elementor-image">
                                                                                    <a
                                                                                        href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
                                                                                        <img
                                                                                            decoding="async"
                                                                                            width="1024"
                                                                                            height="577"
                                                                                            src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2021/04/iris_en1174d.png?fit=1024%2C577&amp;ssl=1"
                                                                                            class="attachment-large size-large"
                                                                                            alt=""
                                                                                            loading="lazy"
                                                                                            
                                                                                            sizes="(max-width: 1024px) 100vw,1024px"
                                                                                        />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    class="elementor-section
elementor-top-section
elementor-element
elementor-element-6880483
elementor-section-boxed
elementor-section-height-default
elementor-section-height-default"
                                                    data-id="6880483"
                                                    data-element_type="section">
                                                    <div
                                                        class="elementor-container
elementor-column-gap-default">
                                                        <div
                                                            class="elementor-row">
                                                            <div
                                                                class="elementor-column
elementor-col-100
elementor-top-column
elementor-element
elementor-element-808b1d6"
                                                                data-id="808b1d6"
                                                                data-element_type="column"
                                                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                <div
                                                                    class="elementor-column-wrap
elementor-element-populated">
                                                                    <div
                                                                        class="elementor-widget-wrap">
                                                                        <div
                                                                            class="elementor-element
elementor-element-fa5322c
elementor-widget
elementor-widget-image"
                                                                            data-id="fa5322c"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default">
                                                                            <div
                                                                                class="elementor-widget-container">
                                                                                <div
                                                                                    class="elementor-image">
                                                                                    <img
                                                                                        decoding="async"
                                                                                        width="1280"
                                                                                        height="5707"
                                                                                        src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2021/04/UBio-X-Iris-VIRDI3a53.png?fit=1280%2C5707&amp;ssl=1"
                                                                                        class="attachment-full
size-full"
                                                                                        alt=""
                                                                                        loading="lazy"

                                                                                        sizes="(max-width:
1200px)
100vw,
1200px"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    class="elementor-section
elementor-top-section
elementor-element
elementor-element-5b12fa2
elementor-section-boxed
elementor-section-height-default
elementor-section-height-default"
                                                    data-id="5b12fa2"
                                                    data-element_type="section"
                                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                    <div
                                                        class="elementor-container
elementor-column-gap-default">
                                                        <div
                                                            class="elementor-row">
                                                            <div
                                                                class="elementor-column
elementor-col-100
elementor-top-column
elementor-element
elementor-element-d986e07"
                                                                data-id="d986e07"
                                                                data-element_type="column">
                                                                <div
                                                                    class="elementor-column-wrap">
                                                                    <div
                                                                        class="elementor-widget-wrap">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    class="elementor-section
elementor-top-section
elementor-element
elementor-element-435a088
elementor-section-boxed
elementor-section-height-default
elementor-section-height-default"
                                                    data-id="435a088"
                                                    data-element_type="section"
                                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                    <div
                                                        class="elementor-container
elementor-column-gap-default">
                                                        <div
                                                            class="elementor-row">
                                                            <div
                                                                class="elementor-column
elementor-col-100
elementor-top-column
elementor-element
elementor-element-9f56be7"
                                                                data-id="9f56be7"
                                                                data-element_type="column">
                                                                <div
                                                                    class="elementor-column-wrap">
                                                                    <div
                                                                        class="elementor-widget-wrap">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </main>
                    </div>
                </div>
            </div>


            <Footer />

        </div>
    );
}

export default Iris;