import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function FakeFinger() {
	return (
		<div>
			<NavBar />

			<div id="content" class="site-content">
				<div class="ast-container">


					<div id="primary" class="content-area primary">


						<main id="main" class="site-main">
							<article
								class="post-669 page type-page status-publish ast-article-single" id="post-669" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
								<header class="entry-header ast-header-without-markup">

								</header>

								<div class="entry-content clear"
									itemprop="text"	>


									<div data-elementor-type="wp-page" data-elementor-id="669" class="elementor elementor-669">
										<div class="elementor-inner">
											<div class="elementor-section-wrap">
												<section class="elementor-section elementor-top-section elementor-element elementor-element-77717e37 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="77717e37" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3fc3d192" data-id="3fc3d192" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-3e2e770d elementor-widget elementor-widget-image" data-id="3e2e770d" data-element_type="widget" data-widget_type="image.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-image">
																					<img decoding="async" width="1242" height="362" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/technology-page0064.jpg?fit=1242%2C362&amp;ssl=1" class="attachment-full size-full" alt="Technology" loading="lazy" 
																					sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-4c9a7c33 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4c9a7c33" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-27bb6ace" data-id="27bb6ace" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-e7eeadd elementor-widget elementor-widget-heading" data-id="e7eeadd" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h6 class="elementor-heading-title elementor-size-default">What is a Fake Fingerprint?</h6>		</div>
																		</div>
																		<div class="elementor-element elementor-element-3caad823 elementor-widget elementor-widget-text-editor" data-id="3caad823" data-element_type="widget" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p>It is an artificial fingerprint made from silicone, rubber, paper, gel, or film which is used to defeat common biometric readers. Our Time &amp; Attendance Solution manages access records obtained from authentication server. There is no need of additional installation of Time &amp; Attendance devices as a <a href="https://customcraftvehicles.com/scam-real-or-fake-marco-lavanna/">fingerprint recognition device can be used</a> as the basis of an integrated system. In addition, access records are strictly managed based on fake fingerprint detection function. Thus, manipulation of work hours can be prevented in advance.</p><p>Our access control solution provides advanced control system for high security and convenience. Users can manage their human resource effectively by monitoring access history and current conditions of visitors <a href="https://hughesairco.com/arizona-az/gilbert/heating-service/">hughesairco.com</a>. Unexpected accidents can be preventedby remote control in emergency. Also make sure there is an annual inspection to ensure the system works perfectly. Confidential documents and assets can be protected by accurate access control solution.</p><p> </p>					</div>
																			</div>
																		</div>
																		<div class="elementor-element elementor-element-140929d elementor-widget elementor-widget-heading" data-id="140929d" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h1 class="elementor-heading-title elementor-size-medium">Fingerprint Time Attendance Terminal </h1>		</div>
																		</div>
																		<div class="elementor-element elementor-element-d30e7e5 elementor-widget elementor-widget-heading" data-id="d30e7e5" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h2 class="elementor-heading-title elementor-size-small">Virdi is the world's first biometric fingerprint attendance terminal company with fictitious fingerprint detection software.</h2>		</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-63c46f9a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="63c46f9a" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-22f6f87d" data-id="22f6f87d" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-34ba3624 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="34ba3624" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7f4f08fa" data-id="7f4f08fa" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-fcc37ed elementor-invisible elementor-widget elementor-widget-heading" data-id="fcc37ed" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default">TYPICAL FAKE FINGERPRINTS
																										</h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-500294db" data-id="500294db" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-d912283 elementor-invisible elementor-widget elementor-widget-image" data-id="d912283" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<img decoding="async" width="1024" height="172" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/fake_fingerprint-12c71.png?fit=1024%2C172&amp;ssl=1" class="attachment-large size-large" alt="TYPICAL FAKE FINGERPRINTS" loading="lazy" 
																											sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-4067afa8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4067afa8" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1572a09d" data-id="1572a09d" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-476cb9a1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="476cb9a1" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2f44e2e3" data-id="2f44e2e3" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-3857681c elementor-invisible elementor-widget elementor-widget-heading" data-id="3857681c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default">ALTERNATIVE FINGERPRINT, TERMINAL MANUFACTURERS WEAKNESS
																										</h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7c1508b8" data-id="7c1508b8" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-7b3c315 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="7b3c315" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInRight&quot;}" data-widget_type="text-editor.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-text-editor elementor-clearfix">
																											<p>Most biometric sensors can be defeated using a variety of commonly known methods. This renders most biometric technology useless as the level of security does not protect businesses from the financial loss through fraudulent clocking, nor does it provide the level of security required by government, airport, military and commercial organizations like howells ac.</p>					</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-3bba56fc elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3bba56fc" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-290838d8" data-id="290838d8" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-5d689a0f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5d689a0f" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3f345bf7" data-id="3f345bf7" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-7a7985ad elementor-invisible elementor-widget elementor-widget-heading" data-id="7a7985ad" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInLeft&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default">VIRDI’S PATENTED LIVE & FAKE FINGER DETECTION TECHNOLOGY
																											(PATENT PROTECTED)
																										</h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7876f111" data-id="7876f111" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-e0cf06b elementor-widget elementor-widget-text-editor" data-id="e0cf06b" data-element_type="widget" data-widget_type="text-editor.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-text-editor elementor-clearfix">
																											<p>VIRDI’s patented optical fingerprint sensor incorporates both LIVE and FAKE finger detection using a combination of the technologies below.</p>					</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-253a7ca elementor-invisible elementor-widget elementor-widget-image" data-id="253a7ca" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<img decoding="async" width="1024" height="617" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/diagram0707.png?fit=1024%2C617&amp;ssl=1" class="attachment-large size-large" alt="Fake Finger Detection in Nigeria Diagram" loading="lazy" 
																											sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-ee9f842 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ee9f842" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-63eae7d" data-id="63eae7d" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-dbb23c7 elementor-invisible elementor-widget elementor-widget-heading" data-id="dbb23c7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default">How to make the fake fingerprints</h3>		</div>
																		</div>
																		<div class="elementor-element elementor-element-c1c2405 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="c1c2405" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<iframe title='d' src="https://www.youtube.com/embed/-H71tyMupqk" width="420" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-63c85ef" data-id="63c85ef" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-b27726b elementor-invisible elementor-widget elementor-widget-heading" data-id="b27726b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default">Showing the fake fingerprints</h3>		</div>
																		</div>
																		<div class="elementor-element elementor-element-5a62541 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="5a62541" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p><iframe title='d' loading="lazy" src="https://www.youtube.com/embed/idS-DSNp-LE" width="420" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>					</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-b842ac8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b842ac8" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ebb425d" data-id="ebb425d" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-8d62432 elementor-invisible elementor-widget elementor-widget-heading" data-id="8d62432" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default">How to verify the fake fingerprints</h3>		</div>
																		</div>
																		<div class="elementor-element elementor-element-cbe0616 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="cbe0616" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p><iframe title='d' loading="lazy" src="https://www.youtube.com/embed/cn2W6ye20IY" width="420" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c2dfb93" data-id="c2dfb93" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-6888a9e elementor-invisible elementor-widget elementor-widget-heading" data-id="6888a9e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInRight&quot;}" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default">Fake Fingerprints Detection</h3>		</div>
																		</div>
																		<div class="elementor-element elementor-element-460dd20 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="460dd20" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInRight&quot;}" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p><iframe title='d' loading="lazy" src="https://www.youtube.com/embed/393N2ywsKos" width="420" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>					</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-6a227ff elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6a227ff" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-cf292e4" data-id="cf292e4" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-654fc03 elementor-invisible elementor-widget elementor-widget-heading" data-id="654fc03" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInLeft&quot;}" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default">Fake Fingerprint Detection (Summary) from ViRDI</h3>		</div>
																		</div>
																		<div class="elementor-element elementor-element-b5082c4 elementor-invisible elementor-widget elementor-widget-text-editor" data-id="b5082c4" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInLeft&quot;}" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p><iframe title='d' loading="lazy" style={{
																						maxWidth: '550px'
																					}} src="https://www.youtube.com/embed/4uMophTKc-s" width="420" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>					</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7a286bf" data-id="7a286bf" data-element_type="column">
																<div class="elementor-column-wrap">
																	<div class="elementor-widget-wrap">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-09ea45e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="09ea45e" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-86b8166" data-id="86b8166" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-56fe3bf elementor-widget elementor-widget-text-editor" data-id="56fe3bf" data-element_type="widget" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p style={{
																						textAlign: 'cente'
																					}}><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Virdi.biz is a leading technology company that focuses on providing fingerprint time 
                attendance solutions to local, regional and global businesses. Our fingerprint time attendance terminal is designed to provide you with a high-quality, 
                affordable and reliable solution for your business.&quot;}" data-sheets-userformat="{&quot;2&quot;:4797,&quot;3&quot;:{&quot;1&quot;:0},
                &quot;5&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;
                2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;6&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,
                &quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,
                &quot;2&quot;:0,&quot;4&quot;:1}]},&quot;7&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;
                2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;8&quot;:
                {&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;
                :0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;10&quot;:2,&quot;12&quot;:0,&quot;
                15&quot;:&quot;Arial&quot;}"><b>Virdi.biz</b> is a leading technology company that focuses on providing fingerprint time
																							attendance solutions to local, regional and global businesses. Our fingerprint time attendance terminal is designed to
																							provide you with a high-quality, affordable and reliable solution for your business.</span></p>					</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>



								</div>



							</article>

						</main>


					</div>


				</div>
			</div>

			<Footer />
		</div>
	)
}
export default FakeFinger;