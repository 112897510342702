import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function MobileCard() {
  return (
    <div>
      <NavBar />

      <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1817 page type-page status-publish ast-article-single" id="post-1817" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1817" class="elementor elementor-1817">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-1684f36a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1684f36a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1bcce5cd" data-id="1bcce5cd" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1aa7bb07 elementor-widget elementor-widget-image" data-id="1aa7bb07" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="633" 
							src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/Mobile-Card-System-1069f.jpg?fit=1024%2C633&amp;ssl=1" class="attachment-large size-large" alt="Mobile Card System-1" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-548a704c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="548a704c" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2c0d5ed0" data-id="2c0d5ed0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-78b6978 elementor-widget elementor-widget-image" data-id="78b6978" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1228" height="361" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/Mobile-Card-System-23f75.jpg?fit=1228%2C361&amp;ssl=1" class="attachment-full size-full" alt="Mobile Card System-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-36f1c073 elementor-widget elementor-widget-image" data-id="36f1c073" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1214" height="507" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/Mobile-Card-System-3f933.jpg?fit=1214%2C507&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

    <Footer />
    </div>
  )
}
export default MobileCard;