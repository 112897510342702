import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import BiometricB from './pages/beathalyze';
import EB030 from './pages/eb030';
import FOH02 from './pages/foho2';
import Home from './pages/home';
import Iris from './pages/iris';
import MCP040 from './pages/mcp040';
import Plus2100 from './pages/plus2100';
import Plus5000 from './pages/plus5000';
import Pro2 from './pages/pro2';
import ProLite from './pages/prolite';
import R20D from './pages/r20d';
import Tablet5 from './pages/tablet5';
import Xface from './pages/xface';
import TimeA from './pages/timeAttendance';
import AccessControl from './pages/accessControl';
import Payroll from './pages/payroll';
import Alpeta from './pages/alphata';
import Unis from './pages/unis';
import MobileCard from './pages/mobilecs';
import FakeFinger from './pages/fakeFinger';
import FvcRanked from './pages/fvcRanked';
import About from './pages/about';
import Contact from './pages/contactUs';
import Associtae from './pages/associate';
import Software from './pages/software';
import Technology from './pages/technology';
import Product from './pages/product';
import Freedemo from './pages/freedemo';
import Blogone from './pages/blog1';
import Blogtwo from './pages/blog2';



function App() {
  

  return(
    <Router>
        <Routes>
          {/* <Route path='/' element={<Footer/>}/> */}
          <Route path='/' element={<Home/>}/>
          <Route path='/time-attendance' element={<TimeA/>}/>
          <Route path='/iris' element={<Iris/>}/>
          <Route path='/xface' element={<Xface/>}/>
          <Route path='/ubio-x-pro-2' element={<Pro2/>}/>
          <Route path='/ubio-x-pro-lite' element={<ProLite/>}/>
          <Route path='/ac-5000-plus' element={<Plus5000/>}/>
          <Route path='/ac-2100-plus' element={<Plus2100/>}/>
          <Route path='/vs-r20d' element={<R20D/>}/>
          <Route path='/mcp-040' element={<MCP040/>}/>
          <Route path='/foh02' element={<FOH02/>}/>
          <Route path='/eb-030' element={<EB030/>}/>
          <Route path='/ubio-tablet5' element={<Tablet5/>}/>
          <Route path='/biometric-breathalyzer' element={<BiometricB/>}/>
          <Route path='/access-control' element={<AccessControl/>}/>
          <Route path='/pay-roll' element={<Payroll/>}/>
          <Route path='/ubio-alpeta' element={<Alpeta/>}/>
          <Route path='/unis' element={<Unis/>}/>
          <Route path='/mobile-card-system' element={<MobileCard/>}/>
          <Route path='/fake-finger' element={<FakeFinger/>}/>
          <Route path='/fvc-ranked-1st' element={<FvcRanked/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/contact-us' element={<Contact/>}/>
          <Route path='/associate-with-us' element={<Associtae/>}/>
          <Route path='/technology' element={<Technology/>}/>
          <Route path='/software' element={<Software/>}/>
          <Route path='/products' element={<Product/>}/>
          <Route path='/bookfreedemo/' element={<Freedemo/>}/>
          <Route path='/virdi-nigeria’s-time-office-software' element={<Blogone/>}/>
          <Route path='/streamlining-payroll-processes' element={<Blogtwo/>}/>
        </Routes>
      </Router>
  );
}

export default App;