import React from 'react'
import Footer from '../partial/footer';
import NavBar from '../partial/navbar';
import './home.css';


function Home() {

  return (
    <div>
      {/* <NavBar/> */}

      <div id="content" class="site-content">
        <div class="ast-container">


          <div id="primary" class="content-area primary">


            <main id="main" class="site-main">
              <article class="post-13 page type-page status-publish ast-article-single" id="post-13"
                itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
                <header class="entry-header ast-header-without-markup">

                </header>
                {/* <!-- .entry-header --> */}

                <div class="entry-content clear" itemprop="text">
                  <div data-elementor-type="wp-post" data-elementor-id="13"
                    class="elementor elementor-13">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-a2da299 meet-hero-one elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="a2da299" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1c105e0"
                                data-id="1c105e0" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-b24c275 meet-hero-two elementor-widget elementor-widget-wp-widget-metaslider_widget"
                                      data-id="b24c275" data-element_type="widget"
                                      data-widget_type="wp-widget-metaslider_widget.default">
                                      <div class="elementor-widget-container">
                                        <div id="metaslider-id-555"
                                          style={{
                                            width: '100%'
                                          }}
                                          class="ml-slider-3-27-8 metaslider metaslider-flex metaslider-555 ml-slider nav-hidden">
                                          <div id="metaslider_container_555">
                                            <div id="metaslider_555">
                                              <ul aria-live="polite"
                                                class="slides">
                                                <li
                                                  style={{
                                                    display: 'block', width: '100%'
                                                  }}
                                                  class="slide-570 ms-image">
                                                  <img width="1240"
                                                    height="700"
                                                    src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/hero-banner-15c5b.jpg?fit=1240%2C700&amp;ssl=1"
                                                    class="slider-555 slide-570"
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    rel=""
                                                    title="hero-banner-1"
                                                    sizes="(max-width: 1200px) 100vw, 1200px" />
                                                </li>
                                                <li style={{
                                                  display: 'block', width: '100%'
                                                }}
                                                  class="slide-566 ms-image">
                                                  <img width="1240"
                                                    height="705"
                                                    src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/08/0_2_banner-img-Va74d.png?fit=1240%2C705&amp;ssl=1"
                                                    class="slider-555 slide-566"
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    rel=""
                                                    title="0_2_banner-img-V"
                                                    sizes="(max-width: 1200px) 100vw, 1200px" />
                                                </li>
                                                <li style={{
                                                  display: 'block', width: '100%'
                                                }}
                                                  class="slide-567 ms-image">
                                                  <img width="1240"
                                                    height="700"
                                                    src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/hero-banner-35c5b.png?fit=1240%2C700&amp;ssl=1"
                                                    class="slider-555 slide-567"
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    rel=""
                                                    title="hero-banner-3"
                                                    sizes="(max-width: 1200px) 100vw, 1200px" />
                                                </li>
                                                <li style={{
                                                  display: 'block', width: '100%'
                                                }}
                                                  class="slide-568 ms-image">
                                                  <img width="1240"
                                                    height="697"
                                                    src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/08/0_4_banner-img-V7e9d.png?fit=1240%2C697&amp;ssl=1"
                                                    class="slider-555 slide-568"
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    rel=""
                                                    title="0_4_banner-img-V"
                                                    sizes="(max-width: 1200px) 100vw, 1200px" />
                                                </li>
                                                <li style={{
                                                  display: 'block', width: '100%'
                                                }}
                                                  class="slide-1293 ms-image">
                                                  <img width="1241"
                                                    height="701"
                                                    src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/08/0_1_banner-img-V1f3a.png?fit=1241%2C701&amp;ssl=1"
                                                    class="slider-555 slide-1293"
                                                    alt=""
                                                    decoding="async"
                                                    loading="lazy"
                                                    rel=""
                                                    title="0_1_banner-img-V"
                                                    sizes="(max-width: 1200px) 100vw, 1200px" />
                                                </li>
                                              </ul>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-b0cbe2e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="b0cbe2e" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd1e665"
                                data-id="bd1e665" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-5bd174a elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="5bd174a" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2
                                          class="elementor-heading-title elementor-size-default">
                                          Our Products</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-c792c9f elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="c792c9f" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h5
                                          class="elementor-heading-title elementor-size-default">
                                          We pride ourselves in delivering the
                                          highest quality standards for Biometric
                                          Terminals,<br />Live Access Controllers,
                                          Slave Readers and Softwares.</h5>
                                      </div>
                                    </div>
                                    <section
                                      class="elementor-section elementor-inner-section elementor-element elementor-element-7bb39aa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="7bb39aa" data-element_type="section">
                                      <div
                                        class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-00e3641 dont-hover"
                                            data-id="00e3641"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-4e25ceb elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="4e25ceb"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/products">
                                                        <img decoding="async"
                                                          width="295"
                                                          height="295"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/biometric2374.png?fit=295%2C295&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy"
                                                          sizes="(max-width: 295px) 100vw, 295px" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-58deb4d"
                                            data-id="58deb4d"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-0cfeb05 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="0cfeb05"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/products">
                                                        <img decoding="async"
                                                          width="298"
                                                          height="298"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/access_controllers8e4f.png?fit=298%2C298&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy"
                                                          sizes="(max-width: 298px) 100vw, 298px" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8d0fb21"
                                            data-id="8d0fb21"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-b9b95e0 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="b9b95e0"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/products">
                                                        <img decoding="async"
                                                          width="299"
                                                          height="297"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/slave_readersc589.png?fit=299%2C297&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy"
                                                          sizes="(max-width: 299px) 100vw, 299px" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    <section
                                      class="elementor-section elementor-inner-section elementor-element elementor-element-91a1699 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="91a1699" data-element_type="section">
                                      <div
                                        class="elementor-container elementor-column-gap-no">
                                        <div class="elementor-row">
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-52963a2"
                                            data-id="52963a2"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-d8025c6 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="d8025c6"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/software">
                                                        <img decoding="async"
                                                          width="273"
                                                          height="282"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/Software01f8.png?fit=273%2C282&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f908dbd"
                                            data-id="f908dbd"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-3549793 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="3549793"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/time-attendance">
                                                        <img decoding="async"
                                                          width="285"
                                                          height="288"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/time_attendance1343.png?fit=285%2C288&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-cdb99de"
                                            data-id="cdb99de"
                                            data-element_type="column"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-db56bfe elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="db56bfe"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/ac-7000">
                                                        <img decoding="async"
                                                          width="278"
                                                          height="284"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/face_scannerbec5.png?fit=278%2C284&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-73efc48 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="73efc48" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cc45d50"
                                data-id="cc45d50" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-8776257 elementor-widget elementor-widget-text-editor"
                                      data-id="8776257" data-element_type="widget"
                                      data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div
                                          class="elementor-text-editor elementor-clearfix">
                                          <p style={{
                                            textAlign: 'center'
                                          }}><span
                                            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Biometric attendance machine is a device that captures the fingerprints or palm print of a person and then compare it with the ones stored in its memory. Biometric attendance machine has become very useful in maintaining security at various places such as schools, offices and many other places. The biometric attendance system software helps to manage these machines easily by automating the data collection process and also providing an interface to view attendance records.&quot;}"
                                            data-sheets-userformat="{&quot;2&quot;:4797,&quot;3&quot;:{&quot;1&quot;:0},&quot;5&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;6&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;7&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;8&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;10&quot;:2,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial&quot;}">Biometric
                                              attendance machine is a device
                                              that captures the fingerprints
                                              or palm print of a person and
                                              then compare it with the ones
                                              stored in its memory. Biometric
                                              attendance machine has become
                                              very useful in maintaining
                                              security at various places such
                                              as schools, offices and many
                                              other places. The <a
                                                href="/software">biometric
                                                attendance system
                                                software</a> helps to manage
                                              these machines easily by
                                              automating the data collection
                                              process and also providing an
                                              interface to view attendance
                                              records.</span></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-1426fd3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="1426fd3" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9187f32"
                                data-id="9187f32" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <section
                                      class="elementor-section elementor-inner-section elementor-element elementor-element-35deb43 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="35deb43" data-element_type="section">
                                      <div
                                        class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-row">
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0df9cc9"
                                            data-id="0df9cc9"
                                            data-element_type="column">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-cdc1aae elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="cdc1aae"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/contact-us">
                                                        <img decoding="async"
                                                          width="290"
                                                          height="106"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/Screenshot-60642.png?fit=290%2C106&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-917edee"
                                            data-id="917edee"
                                            data-element_type="column">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-082bba1 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="082bba1"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/contact-us">
                                                        <img decoding="async"
                                                          width="289"
                                                          height="108"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/Screenshot-7b880.png?fit=289%2C108&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d6d34b9"
                                            data-id="d6d34b9"
                                            data-element_type="column">
                                            <div
                                              class="elementor-column-wrap elementor-element-populated">
                                              <div
                                                class="elementor-widget-wrap">
                                                <div class="elementor-element elementor-element-c174bb9 elementor-invisible elementor-widget elementor-widget-image"
                                                  data-id="c174bb9"
                                                  data-element_type="widget"
                                                  data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                                  data-widget_type="image.default">
                                                  <div
                                                    class="elementor-widget-container">
                                                    <div
                                                      class="elementor-image">
                                                      <a
                                                        href="/contact-us">
                                                        <img decoding="async"
                                                          width="288"
                                                          height="108"
                                                          src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2022/11/Screenshot-82482.png?fit=288%2C108&amp;ssl=1"
                                                          class="attachment-large size-large"
                                                          alt=""
                                                          loading="lazy" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-b0cbe2e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="b0cbe2e" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd1e665"
                                data-id="bd1e665" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-5bd174a elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="5bd174a" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2
                                          class="elementor-heading-title elementor-size-default">
                                          Why Choose Our <br /> Biometric Attendance Devices?</h2>
                                      </div>
                                    </div>
                                    <section>
                                      <div class="all-why-card">
                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-headset" viewBox="0 0 16 16">
                                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                          </svg>
                                          <h4>24X7 Support Policy</h4>
                                          <p><b>VIRDI NIGERIA</b> provides 24x7 support policy depending on the nature of the technical glitch faced by
                                            the clients. Our highly experienced support Engineers are spread PAN Nigeria to cater our users through
                                            On Site or Off site support.</p>
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
                                            <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                                          </svg>
                                          <h4>Customized Solutions</h4>
                                          <p><b>VIRDI NIGERIA</b> offers Customized solutions depending on the requirement of the customers. Our Design and
                                            Development team work closely with other team to help customize Hardware/Software as per the demand.</p>
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-buildings" viewBox="0 0 16 16">
                                            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                                            <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                                          </svg>
                                          <h4>In-House Design and Development</h4>
                                          <p><b>VIRDI NIGERIA</b> has its in-house design and development team which works meticulously to incorporate latest technology
                                            into their systems and solutions. We stand tall in grabbing the latest trend, which technology has to deliver.</p>
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                          </svg>
                                          <h4>PAN NIGERIA Sales and Support</h4>
                                          <p><b>VIRDI NIGERIA</b> is spread across the nation with head office in Lagos. Our Sales and Support team cater different vertical
                                            of the industry across the Nation.</p><br />
                                        </div>

                                      </div>
                                      <div class="all-why-card-mobile">
                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-headset" viewBox="0 0 16 16">
                                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                          </svg>
                                          <h4>24X7 Support Policy</h4>
                                          <p><b>VIRDI NIGERIA</b> provides 24x7 support policy depending on the nature of the technical glitch faced by
                                            the clients. Our highly experienced support Engineers are spread PAN Nigeria to cater our users through
                                            On Site or Off site support.</p>
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
                                            <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                                          </svg>
                                          <h4>Customized Solutions</h4>
                                          <p><b>VIRDI NIGERIA</b> offers Customized solutions depending on the requirement of the customers. Our Design and
                                            Development team work closely with other team to help customize Hardware/Software as per the demand.</p><br />
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-buildings" viewBox="0 0 16 16">
                                            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                                            <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                                          </svg>
                                          <h4>In-House Design and Development</h4>
                                          <p><b>VIRDI NIGERIA</b> has its in-house design and development team which works meticulously to incorporate latest technology
                                            into their systems and solutions. We stand tall in grabbing the latest trend, which technology has to deliver.</p>
                                        </div>

                                        <div class="why-card">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2a51eab1" class="bi bi-flag-fill" viewBox="0 0 16 16">
                                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                          </svg>
                                          <h4>PAN NIGERIA Sales and Support</h4>
                                          <p><b>VIRDI NIGERIA</b> is spread across the nation with head office in Lagos. Our Sales and Support team cater different vertical
                                            of the industry across the Nation.</p><br />
                                        </div>

                                      </div>
                                    </section>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-b0cbe2e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="b0cbe2e" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd1e665"
                                data-id="bd1e665" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-5bd174a elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="5bd174a" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <div class="recent-blog">
                                          <div class="lineA"></div>
                                          <h2 class="why-us-title elementor-heading-title elementor-size-default">Our Recent Blogs</h2>
                                          <div class="lineA"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <section>
                                      <div class="all-blog-card">
                                        <div class="single-blog-card">
                                          <h5>Virdi Nigeria’s Time Office Software: Efficient Web and Window Based Solution</h5><br/><br/>
                                          <p>VIRDI Nigeria's time office software is an efficient web and window-based solution 
                                            designed to streamline time and attendance management processes.
                                          </p>
                                          <div>
                                            <a class="readmore" href="/virdi-nigeria’s-time-office-software"> Read More</a>
                                          </div>
                                          
                                        </div>
                                        <div class="single-blog-card">
                                          <h5>Streamlining payroll processes: The Advantages of using a Payroll Management System</h5><br/>
                                          <p>Payroll management system is the most efficient business tool any business can possess, Why? 
                                            This is because using a Payroll Management System offers several advantages in streamlining 
                                            payment processes of your staffs.
                                          </p>
                                          <div>
                                            <a class="readmore" href="/streamlining-payroll-processes"> Read More</a>
                                          </div>
                                      </div>
                                      </div>
                                      <div class="all-blog-card-mobile">
                                        <div class="single-blog-card">
                                          <h5>Virdi Nigeria’s Time Office Software: Efficient Web and Window Based Solution</h5><br/><br/>
                                          <p>VIRDI Nigeria's time office software is an efficient web and window-based solution 
                                            designed to streamline time and attendance management processes.
                                          </p>
                                          <div>
                                            <a class="readmore" href="/virdi-nigeria’s-time-office-software"> Read More</a>
                                          </div>
                                          
                                        </div>
                                        <div class="single-blog-card">
                                          <h5>Streamlining payroll processes: The Advantages of using a Payroll Management System</h5><br/>
                                          <p>Payroll management system is the most efficient business tool any business can possess, Why? 
                                            This is because using a Payroll Management System offers several advantages in streamlining 
                                            payment processes of your staffs.
                                          </p>
                                          <div>
                                            <a class="readmore" href="/streamlining-payroll-processes"> Read More</a>
                                          </div>
                                      </div>
                                      </div>
                                    </section>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="elementor-section elementor-top-section elementor-element elementor-element-b0cbe2e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="b0cbe2e" data-element_type="section"
                          data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                              <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bd1e665"
                                data-id="bd1e665" data-element_type="column">
                                <div
                                  class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-5bd174a elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="5bd174a" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2
                                          class="elementor-heading-title elementor-size-default">
                                          Our Clients</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-c792c9f elementor-invisible elementor-widget elementor-widget-heading"
                                      data-id="c792c9f" data-element_type="widget"
                                      data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                                      data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h5
                                          class="elementor-heading-title elementor-size-default">
                                          Our clients, like our people are our most treasured assets.
                                          <br /> We nurture every client relationship with commitment, passion and integrity,<br />
                                          which is the reason why most of our clients
                                          treasure the relationship.</h5>
                                      </div>
                                    </div>
                                    <section class="container">
                                      <div class="clients">
                                        <div class="client1">
                                          <div class="galary"><img class="gimg" src="assets/clients/15.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/14.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/13.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/12.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/3.png" alt="" /></div>
                                        </div>

                                        <div class="client1">
                                          <div class="galary"><img class="gimg" src="assets/clients/11.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/10.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/9.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/8.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/2.png" alt="" /></div>
                                        </div>

                                        <div class="client1">
                                          <div class="galary"><img class="gimg" src="assets/clients/7.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/6.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/5.png" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/4.jpg" alt="" /></div>
                                          <div class="galary"><img class="gimg" src="assets/clients/1.png" alt="" /></div>
                                        </div>

                                        <div class="client1">



                                        </div>
                                      </div>

                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>

                </div>

              </article>

            </main>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home;