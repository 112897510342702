
import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function AccessControl(){
    return(
        <div>
            {/* <NavBar/> */}

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1285 page type-page status-publish ast-article-single" id="post-1285" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1285" class="elementor elementor-1285">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-40523fb4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="40523fb4" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dbf4f6f" data-id="dbf4f6f" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1b6cfe3c elementor-widget elementor-widget-image" data-id="1b6cfe3c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1261" height="366" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/access-mgt-banner24ea.jpg?fit=1261%2C366&amp;ssl=1" class="attachment-full size-full" alt="Access Control Management in Nigeria" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-11cbeb7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="11cbeb7" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67f2fac6" data-id="67f2fac6" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-2ee953e elementor-widget elementor-widget-heading" data-id="2ee953e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h1 class="elementor-heading-title elementor-size-medium">Biometric Time Attendance System in Nigeria</h1>		</div>
				</div>
				<div class="elementor-element elementor-element-5406ab0 elementor-widget elementor-widget-heading" data-id="5406ab0" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-medium">Real time attendance software is your perfect companion to enforce accountability and efficiency with your employees.</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-6e615756 elementor-widget elementor-widget-text-editor" data-id="6e615756" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
								<div class="elementor-text-editor elementor-clearfix">
				<p>It is an artificial fingerprint made from silicone, rubber, paper, gel, or film which is used to defeat common biometric readers. Our Time &amp; Attendance Solution manages access records obtained from authentication server. There is no need of additional installation of Time &amp; Attendance devices as a fingerprint recognition device can be used as the basis of an integrated system, find more here.</p><p>In addition, access records are strictly managed based on fake fingerprint detection function. Thus, manipulation of work hours can be prevented in advance. If you are looking for adle3900hd you can find it here. Visit bluesprucemaids.com.</p><p>Our access control solution provides advanced control system for high security and convenience. Users can manage their human resource effectively by monitoring access history and current conditions of visitors depending on the country law. Unexpected accidents can be preventedby remote control in emergency. Confidential documents and assets can be protected by accurate access control solution. Visit uptownjungle.com.</p><p>Visit our website to know more about Union Community Co painterly.ie. Ltd and their brand, ViRDI to more than 120 countries in worldwide and consistently innovating new biometric solutions for user convenience.</p>					</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-a7ab4c2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a7ab4c2" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-636d2a25" data-id="636d2a25" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-cfa263c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cfa263c" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f39cdd" data-id="f39cdd" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-75577957 elementor-invisible elementor-widget elementor-widget-heading" data-id="75577957" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">FEATURES
</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5df34639" data-id="5df34639" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-6e38409f elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="6e38409f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items">
							<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Control by door, district, and time</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Remote control of branch and office</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Compatible with warning event and alarm</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Compatible with Time & Attendance, Meal and Vehicle system</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Compatible with Image surveillance and Integrated control system</span>
									</li>
						</ul>
				</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-70d81df7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="70d81df7" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-77682022" data-id="77682022" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-68103d7f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="68103d7f" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7eae9695" data-id="7eae9695" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1b038162 elementor-invisible elementor-widget elementor-widget-heading" data-id="1b038162" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">EXPECTATION
</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a442242" data-id="a442242" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-7487e014 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="7487e014" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInRight&quot;}" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items">
							<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Effective control of visitors by time and place</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Daily/ monthly reports by accurate data increase work effectiveness</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Protection of confidential documents and assets by authority management</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Saving expenses by minimizing the number of admin</span>
									</li>
						</ul>
				</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-70ecf92 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="70ecf92" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1bacfb96" data-id="1bacfb96" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-4f54b22a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4f54b22a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2883ea0a" data-id="2883ea0a" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-19c2c5dd elementor-invisible elementor-widget elementor-widget-heading" data-id="19c2c5dd" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">APPLICATION
</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1bc8f8ff" data-id="1bc8f8ff" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-22b8c852 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-invisible elementor-widget elementor-widget-icon-list" data-id="22b8c852" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInRight&quot;}" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items">
							<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Government</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Financial Institutes</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Research Institutes</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Military office</span>
									</li>
								<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<i aria-hidden="true" class="fas fa-check"></i>						</span>
										<span class="elementor-icon-list-text">Factory</span>
									</li>
						</ul>
				</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6876f2ae elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6876f2ae" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-36416802" data-id="36416802" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-5eeb5f11 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5eeb5f11" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-26631882" data-id="26631882" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-6f747ec2 elementor-invisible elementor-widget elementor-widget-heading" data-id="6f747ec2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">CONFIGURATION</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-53e6e4ea" data-id="53e6e4ea" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-5cb847f9 elementor-invisible elementor-widget elementor-widget-image" data-id="5cb847f9" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInRight&quot;}" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="496" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/ac-1a7bd.jpg?fit=1024%2C496&amp;ssl=1" class="attachment-large size-large" alt="Configuration" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-14c7d43 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="14c7d43" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2fde20d" data-id="2fde20d" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-bed51b0 elementor-widget elementor-widget-text-editor" data-id="bed51b0" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
								<div class="elementor-text-editor elementor-clearfix">
				<p style={{
                    textAlign: 'center'
                }}><span data-sheets-value="{" data-sheets-userformat="{"> Virdi.biz Access Control is an easy-to-use real time attendance software that helps you to manage your employees&#8217; attendance, check out and check in records, leaves, and so on. You can also use this software to generate reports and print them as hard copies.The software offers a simple and intuitive user interface that allows even non-technical users to manage the system without any hassles. It supports multiple currency rates and comes with a number of features like auto-backup, auto-restore, password protection, etc.</span></p>					</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default AccessControl;