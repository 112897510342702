import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Plus5000(){
    return(
        <div>
            <NavBar/>


            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1647 page type-page status-publish ast-article-single" id="post-1647" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1647" class="elementor elementor-1647">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-693d16a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="693d16a8" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-11086b78" data-id="11086b78" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-78c25b3d elementor-widget elementor-widget-image" data-id="78c25b3d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="712" 
							src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-19274.jpg?fit=1024%2C712&amp;ssl=1" 
							class="attachment-large size-large" alt="AC-5000 PLUS" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6930ae44 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6930ae44" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-57cc2b8e" data-id="57cc2b8e" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-11c02acd elementor-widget elementor-widget-image" data-id="11c02acd" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1240" height="627" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-2aac3.jpg?fit=1240%2C627&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-6346571 elementor-widget elementor-widget-image" data-id="6346571" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1235" height="657" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-3b019.jpg?fit=1235%2C657&amp;ssl=1" class="attachment-full size-full" alt="AC-5000PLUS-3" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-8300b3d elementor-widget elementor-widget-image" data-id="8300b3d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1234" height="680" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-49e86.jpg?fit=1234%2C680&amp;ssl=1" class="attachment-full size-full" alt="Plus - 4" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-99af823 elementor-widget elementor-widget-image" data-id="99af823" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="678" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-59594.jpg?fit=1237%2C678&amp;ssl=1" class="attachment-full size-full" alt="AC-5000PLUS-5" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-33f7287c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="33f7287c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4573f3" data-id="4573f3" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-5ff20b0 elementor-widget elementor-widget-image" data-id="5ff20b0" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="654" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-5000PLUS-6specs680a.jpg?fit=1024%2C654&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>
            <Footer/>
        </div>
    )
}

export default Plus5000;