import React from 'react'
import './navbar.css'

function Footer() {
	return (
		<footer>
			<div class="container">
				<div class="row group">
					<div class="col-md-6">
						<div class="footer-grid-one footer-grid">
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Biometric/RFID
									Terminals</strong></li>
								<li><a href="/ubio-x-iris">UBio-X
									Iris</a></li>
								<li><a href="/ubio-x-face">UBio-X
									Face</a></li>
								<li><a href="/ubio-x-pro-2">UBio-X
									Pro 2</a></li>
								<li><a href="/ubio-x-pro-lite">UBio-X
									Pro Lite</a></li>
								<li><a href="/ac-7000">AC-7000</a>
								</li>
								<li><a href="/ac-5000-plus">AC-5000
									PLUS</a></li>
								<li><a href="/ac-2100-plus">AC-2100
									PLUS</a></li>
								<li><a
									href="/sr-100fp">SR-100FP</a>
								</li>
								<li><a href="/vs-r20d">VS-R20D</a>
								</li>
								<li><a
									href="/biometric-breathalyzer">Biometric
									Breathalyzer</a></li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>USB Scanner</strong></li>
								<li><a href="/foh02">FOH02</a>
								</li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Accessory</strong></li>
								<li><a href="/eb-030">EB-030</a>
								</li>
								<li><a
									href="/enclosure-portable-units">Enclosure
									&amp; Portable</a></li>
							</ul>
						</div>
					</div>
					<div class="col-md-6">
						<div class="footer-grid-two footer-grid">
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Software</strong></li>
								<li><a href="/ubio-alpeta">UBio
									Alpetag</a></li>
								<li><a href="/unis">UNIS</a></li>
								<li><a href="/mobile-card-system">Mobile
									Card System</a></li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Portable Device</strong>
								</li>
								<li><a href="/ubio-tablet5">UBio
									Tablet5</a></li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Access Controller</strong>
								</li>
								<li><a href="/mcp-040">MCP-040</a>
								</li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Solution</strong></li>
								<li><a href="/solutions-2">Access
									Control</a></li>
								<li><a href="/time-attendance">Time
									&amp; Attendance</a></li>
							</ul>
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Technology</strong></li>
								<li><a href="/fvc-ranked-1st">Fake
									fingerprint Detection</a></li>
								<li><a href="/fvc-ranked-1st">FVC
									Ranked 1st</a></li>
								<li><a href="/associate-with-us">Associate
									With Us</a></li>
							</ul>
						</div></div>
					<div class="col-md-6">
						<div class="footer-grid-three footer-grid">
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Contact Us</strong></li>
								<li><i class="fa fa-map-marker-alt"></i> 6A, Oremeji Street, Off Coker Road, Ilupeju,<br /> Lagos,Nigeria.</li>
								<li><a href="/tel:+2349087522522"><i class="fa fa-phone"></i> +234 908 752 2522</a></li>
								<li><i class="far fa-envelope"></i> info@virdi.biz</li>
							</ul>
						</div>
						<div class="footer-grid-three footer-grid">
							<ul>
								<li style={{ listStyleType: 'none' }} class="footer-active"><strong>Follow Us</strong></li>
								<li><a href="https://www.facebook.com/virdinigeria/" class="fa fa-facebook"></a></li>
								<li><a href="https://www.instagram.com/virdinigeria/" class="fa fa-instagram"></a></li>
								<li><a href="https://www.linkedin.com/in/virdi-nigeria-51ab12220/" class="fa fa-linkedin"></a></li>
							</ul>
						</div>
					</div>
				</div>
				<hr />
				<p class="ppage">&copy; 2018 VIRDI NIGERIA LIMITED</p>
			</div>
		</footer>

	)
}

export default Footer;