import React from 'react';
import Footer from '../partial/footer';
import './home.css';


function Freedemo() {
    
  return (
    <div>
        <div class="containerFD"><br />
            
            <div class="dmimg">
                <img src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/ubio-xproa6f2.jpg?fit=208%2C318&amp;ssl=1" alt="" />
                <img src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/ubio-xpro2fba2.jpg?fit=214%2C299&amp;ssl=1" alt="" />
                <img src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/Ac-70000c77c.jpg?fit=197%2C294&amp;ssl=1" alt="" />
            </div><br /><br />
            <h3 class="fdm">Free Demo</h3>
            <p class='fdm'>Kindly place an order for booking a free demo</p>
            <div class='dmimg'>
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ab9b502" data-id="ab9b502" data-element_type="column">
                <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-6fee585 elementor-widget elementor-widget-wpforms" data-id="6fee585" data-element_type="widget" data-widget_type="wpforms.default">
                            <div class="elementor-widget-container">
                                <div class="wpforms-container " id="wpforms-2854">
                                    <form class="wpforms-validate wpforms-form" method="GET" enctype="multipart/form-data"
                                        action="mailto:abhay@lagosmart.net">
                                        <div class="wpforms-field-container">
                                            <div id="wpforms-2854-field_0-container" class="wpforms-field wpforms-field-name" data-field-id="0">
                                                <label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_0">
                                                    Name <span class="wpforms-required-label">*</span></label>
                                                <input type="text" id="wpforms-2854-field_0" class="wpforms-field-large wpforms-field-required" name="fullname" placeholder="Your Name" required />
                                            </div>
                                            <div id="wpforms-2854-field_0-container" class="wpforms-field wpforms-field-name" data-field-id="0">
                                                <label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_0">
                                                    Name <span class="wpforms-required-label">*</span></label>
                                                    
                                                <input type="text" id="wpforms-2854-field_0" class="wpforms-field-large wpforms-field-required" name="comapnyname" placeholder="Company Name" required />
                                            </div>
                                            <div id="wpforms-2854-field_1-container" class="wpforms-field wpforms-field-email" data-field-id="1">
                                                <label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_1">Email <span class="wpforms-required-label">*</span></label>
                                                <input type="email" id="wpforms-2854-field_1" class="wpforms-field-large wpforms-field-required" name="email" placeholder="Email" required /></div>
                                            <div id="wpforms-2854-field_4-container" class="wpforms-field wpforms-field-number" data-field-id="4">
                                                <label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_4">Contact No. <span class="wpforms-required-label">*</span></label>
                                                <input type="number" pattern="\d*" id="wpforms-2854-field_4" class="wpforms-field-large wpforms-field-required" name="contact" placeholder="Contact No." required /></div>
                                            <div id="wpforms-2854-field_9-container" class="wpforms-field wpforms-field-number" data-field-id="9">
                                                <label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_9">City
                                                    <span class="wpforms-required-label">*</span></label>
                                                <input type="text" class="wpforms-field-large wpforms-field-required" name="location" placeholder="Location" required />
                                            </div>
                                        </div>
                                        <center><div class="wpforms-submit-container">
                                            <button type="submit" name="wpforms[submit]" class="wpforms-submit">Send
                                            </button>
                                        </div></center>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
export default Freedemo;