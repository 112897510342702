import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function EB030(){
    return(
        <div>
            <NavBar/>

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1747 page type-page status-publish ast-article-single" id="post-1747" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1747" class="elementor elementor-1747">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-6b7a6120 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6b7a6120" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3649df90" data-id="3649df90" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-53845ed2 elementor-widget elementor-widget-image" data-id="53845ed2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="612" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/EB-030-1c88c.jpg?fit=1024%2C612&amp;ssl=1" class="attachment-large size-large" alt="EB-030-1" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-572183df elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="572183df" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-39aaa0fc" data-id="39aaa0fc" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-648485d6 elementor-widget elementor-widget-image" data-id="648485d6" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="358" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/EB-030-212b3.jpg?fit=1237%2C358&amp;ssl=1" class="attachment-full size-full" alt="EB-030-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-595d072f elementor-widget elementor-widget-image" data-id="595d072f" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1223" height="402" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/EB-030-3be06.jpg?fit=1223%2C402&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-7dc7a876 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7dc7a876" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-17222537" data-id="17222537" data-element_type="column">
			<div class="elementor-column-wrap">
							<div class="elementor-widget-wrap">
								</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default EB030;