import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Product() {
	return (
		<div>
			<NavBar />



			<div id="content" class="site-content">
				<div class="ast-container">


					<div id="primary" class="content-area primary">


						<main id="main" class="site-main">
							<article
								class="post-663 page type-page status-publish ast-article-single" id="post-663" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
								<header class="entry-header ast-header-without-markup">

								</header>

								<div class="entry-content clear"
									itemprop="text"	>


									<div data-elementor-type="wp-page" data-elementor-id="663" class="elementor elementor-663">
										<div class="elementor-inner">
											<div class="elementor-section-wrap">
												<section class="elementor-section elementor-top-section elementor-element elementor-element-d022704 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d022704" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fcb7470" data-id="fcb7470" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap" style={{
																					backgroundColor:'#F3EDED',marginTop:'30px',textAlign:'center',width:'100%'
																				}}>
																		<div class="elementor-element elementor-element-09061f5 elementor-widget elementor-widget-heading" data-id="09061f5" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h3 class="elementor-heading-title elementor-size-default" style={{
																					color:'#3399CC'
																				}}>Biometric/RFID Terminals</h3></div>
																		</div>
																		<div class="elementor-element elementor-element-dc74136 elementor-widget elementor-widget-heading" data-id="dc74136" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h1 class="elementor-heading-title elementor-size-medium">
																					<span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Biometric Machine Price in Nigeria&quot;}"
																						data-sheets-userformat="{&quot;2&quot;:16957,&quot;3&quot;:{&quot;1&quot;:0},&quot;5&quot;:{&quot;1&quot;:
																						[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,
																						&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;6&quot;:
																						{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},
																						{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},
																						&quot;7&quot;:{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},
																						{&quot;1&quot;:0,&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;8&quot;:
																						{&quot;1&quot;:[{&quot;1&quot;:2,&quot;2&quot;:0,&quot;5&quot;:{&quot;1&quot;:2,&quot;2&quot;:0}},{&quot;1&quot;:0,
																						&quot;2&quot;:0,&quot;3&quot;:3},{&quot;1&quot;:1,&quot;2&quot;:0,&quot;4&quot;:1}]},&quot;12&quot;:0,&quot;17&quot;:1}"
																						style={{
																							fontStyle: 'normal',
																							whiteSpace: 'normal',
																							fontSize: '10pt',
																							fontFamily: 'Arial'
																						}}>
																						<b>Biometric Machine Price in Nigeria</b></span></h1>		</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-7b3642b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7b3642b" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-86552d2" data-id="86552d2" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-45add89 elementor-widget elementor-widget-heading" data-id="45add89" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h2 class="elementor-heading-title elementor-size-large" style={{textAlign:'center', marginTop:'30px'}}>Buy Biometric Machine & Biometric Solutions in Nigeria</h2>		</div>
																		</div>
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-a41cc72 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a41cc72" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-938e235" data-id="938e235" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-9efeed0 elementor-invisible elementor-widget elementor-widget-image" data-id="9efeed0" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="214" height="299" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/ubio-xpro2fba2.jpg?fit=214%2C299&amp;ssl=1" class="attachment-large size-large" alt="UBio-X Pro 2" loading="lazy" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-4131170 elementor-widget elementor-widget-heading" data-id="4131170" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">UBio-X Pro 2</a></h4>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5aa37b5" data-id="5aa37b5" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-3801f7e elementor-invisible elementor-widget elementor-widget-image" data-id="3801f7e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInRight&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="208" height="318" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/ubio-xproa6f2.jpg?fit=208%2C318&amp;ssl=1" 
																												class="attachment-large size-large" alt="UBio-X Pro" loading="lazy" 
																												sizes="(max-width: 208px) 100vw, 208px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-59b36ca elementor-widget elementor-widget-heading" data-id="59b36ca" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">UBio-X Pro Lite</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-881c541" data-id="881c541" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-78ac27e elementor-invisible elementor-widget elementor-widget-image" data-id="78ac27e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;rotateInUpRight&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="197" height="294" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/Ac-70000c77c.jpg?fit=197%2C294&amp;ssl=1" 
																												class="attachment-large size-large" alt="AC-7000" loading="lazy" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-f8eeaa8 elementor-widget elementor-widget-heading" data-id="f8eeaa8" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">AC-7000</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-cb4a1e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cb4a1e1" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-848779c" data-id="848779c" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-cb7d1bb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cb7d1bb" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6c15766" data-id="6c15766" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-7a0282b elementor-invisible elementor-widget elementor-widget-image" data-id="7a0282b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;bounceInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="186" height="324" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/AC-50000-PLUS6b97.jpg?fit=186%2C324&amp;ssl=1" 
																												class="attachment-large size-large" alt="AC-5000 PLUS" loading="lazy" 
																												sizes="(max-width: 186px) 100vw, 186px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-c7345b6 elementor-widget elementor-widget-heading" data-id="c7345b6" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">AC-5000 PLUS</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2ad900a" data-id="2ad900a" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-52e5c02 elementor-invisible elementor-widget elementor-widget-image" data-id="52e5c02" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zoomInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="78" height="252" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/AC-2100-PLUSf039.jpg?fit=78%2C252&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-deb4120 elementor-widget elementor-widget-heading" data-id="deb4120" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">AC-2100 PLUS</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2db2381" data-id="2db2381" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-410e49a elementor-invisible elementor-widget elementor-widget-image" data-id="410e49a" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="130" height="304" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/SR-100FP2cda.jpg?fit=130%2C304&amp;ssl=1" 
																												class="attachment-large size-large" alt="SR-100FP" loading="lazy"
																												sizes="(max-width: 130px) 100vw, 130px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-bc799ca elementor-widget elementor-widget-heading" data-id="bc799ca" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">SR-100FP</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-f4b638f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f4b638f" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3cde2f7" data-id="3cde2f7" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-cae0857 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cae0857" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c8b1cc7" data-id="c8b1cc7" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-43785ce elementor-invisible elementor-widget elementor-widget-image" data-id="43785ce" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="135" height="326" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/VS-R20D742c.jpg?fit=135%2C326&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
																												 sizes="(max-width: 135px) 100vw, 135px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-2dbb358 elementor-widget elementor-widget-heading" data-id="2dbb358" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">VS-R20D</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-4a30f7a" data-id="4a30f7a" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-49954a2 elementor-invisible elementor-widget elementor-widget-image" data-id="49954a2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="l">
																												<img decoding="async" width="204" height="350" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/MCP-040db6e.jpg?fit=204%2C350&amp;ssl=1" class="attachment-large size-large" alt="MCP-040" loading="lazy" 
																												 
																												 sizes="(max-width: 204px) 100vw, 204px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-877f467 elementor-widget elementor-widget-heading" data-id="877f467" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">MCP-040</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-59d7dd1" data-id="59d7dd1" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-93eef73 elementor-invisible elementor-widget elementor-widget-image" data-id="93eef73" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="299" height="317" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/FOH02d233.jpg?fit=299%2C317&amp;ssl=1" 
																												class="attachment-large size-large" alt="FOH02" loading="lazy"  sizes="(max-width: 299px) 100vw, 299px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-cbee369 elementor-widget elementor-widget-heading" data-id="cbee369" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">FOH02</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-e39327e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e39327e" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8abd4a7" data-id="8abd4a7" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-ba66247 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ba66247" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-251d2b1" data-id="251d2b1" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-e6c81fa elementor-invisible elementor-widget elementor-widget-image" data-id="e6c81fa" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="164" height="309" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/UBio-Tablet544cc.jpg?fit=164%2C309&amp;ssl=1" 
																												class="attachment-large size-large" alt="" loading="lazy" sizes="(max-width: 164px) 100vw, 164px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-9067bc9 elementor-widget elementor-widget-heading" data-id="9067bc9" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">UBio Tablet5</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c15c1d1" data-id="c15c1d1" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-8301d0f elementor-invisible elementor-widget elementor-widget-image" data-id="8301d0f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="330" height="355" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/EB-030c9a3.jpg?fit=330%2C355&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
																												 sizes="(max-width: 330px) 100vw, 330px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-691e7a7 elementor-widget elementor-widget-heading" data-id="691e7a7" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">EB-030</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-153986a" data-id="153986a" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-374639f elementor-invisible elementor-widget elementor-widget-image" data-id="374639f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="346" height="278" 
																												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/Enclosure-Portable-Units4f46.jpg?fit=346%2C278&amp;ssl=1" 
																												class="attachment-large size-large" alt="Enclosure &amp; Portable Units" loading="lazy" 
																												 sizes="(max-width: 346px) 100vw, 346px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-4aae78e elementor-widget elementor-widget-heading" data-id="4aae78e" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">Enclosure & Portable Units</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-ec4187b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ec4187b" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-207cff2" data-id="207cff2" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-9749668 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9749668" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f203dbd" data-id="f203dbd" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-14d1e55" data-id="14d1e55" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-5d27347 elementor-invisible elementor-widget elementor-widget-image" data-id="5d27347" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<a href="">
																												<img decoding="async" width="1024" height="653" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/biometric-breathalyzerb8e5.jpg?fit=1024%2C653&amp;ssl=1" class="attachment-large size-large" alt="Eggle Search International - Biometric Breathalyzer in Nigeria" loading="lazy" 
																												sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
																										</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-5fd7fad elementor-widget elementor-widget-heading" data-id="5fd7fad" data-element_type="widget" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h4 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}><a href="">Biometric Breathalyzer</a></h4>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ca6a062" data-id="ca6a062" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-94b97e9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="94b97e9" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c327964" data-id="c327964" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-b7987b2 elementor-widget elementor-widget-text-editor" data-id="b7987b2" data-element_type="widget" data-widget_type="text-editor.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-text-editor elementor-clearfix">
																					<p style={{
																						textAlign: 'center'
																					}}><span style={{
																						fontSize: '15px'
																					}}><b>Virdi.biz is a leading provider of biometric machines in Nigeria. We offer the latest facial thermal scanners and fingerprinting devices at competitive prices. If you are looking for a reliable supplier of biometric machines, contact us today!</b></span></p>					</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>



								</div>



							</article>

						</main>


					</div>


				</div>
			</div>


			<Footer />
		</div>
	)
}

export default Product;