import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function Payroll() {
  return (
    <div>
      <NavBar />


      <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1349 page type-page status-publish ast-article-single" id="post-1349" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1349" class="elementor elementor-1349">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-4a477496 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4a477496" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35499cb3" data-id="35499cb3" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-53a8a1c elementor-widget elementor-widget-image" data-id="53a8a1c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="297" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/pay-roll-banner09ee.jpg?fit=1024%2C297&amp;ssl=1" 
												class="attachment-large size-large" alt="Eggle Search International - Payroll Solution Nigeria" loading="lazy" 
												
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-3423ea7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3423ea7" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5c0a78a5" data-id="5c0a78a5" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-4e5730a5 elementor-widget elementor-widget-text-editor" data-id="4e5730a5" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
								<div class="elementor-text-editor elementor-clearfix">
				<p>Pay Roll is a software for generating pay slips independently. It provides a simple interface to make generating pay slip both easy and fun. You can add hourly payments, allowances, deductions; generate, edit and email slips to employees using this simple pay roll application.</p></div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-1007af5b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1007af5b" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9b152a8" data-id="9b152a8" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-2a886ffa elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2a886ffa" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-577cf20a" data-id="577cf20a" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-7eedb50 elementor-invisible elementor-widget elementor-widget-heading" data-id="7eedb50" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">USER INTERFACE</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-43eb6b35" data-id="43eb6b35" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1940e9d0 elementor-invisible elementor-widget elementor-widget-image" data-id="1940e9d0" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInRight&quot;}" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="673" height="327" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/pay-roll-21441.jpg?fit=673%2C327&amp;ssl=1" class="attachment-large size-large" alt="Pay Roll" loading="lazy" 
												sizes="(max-width: 673px) 100vw, 673px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-254be12 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="254be12" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9a97869" data-id="9a97869" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-d9ae66e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d9ae66e" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6b2f26c" data-id="6b2f26c" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1abfd75 elementor-invisible elementor-widget elementor-widget-heading" data-id="1abfd75" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">EXPENSE CLAIM</h4>		</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-09a41f5" data-id="09a41f5" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-52e8410 elementor-invisible elementor-widget elementor-widget-image" data-id="52e8410" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInRight&quot;}" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="693" height="334" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/expense-claim52de.jpg?fit=693%2C334&amp;ssl=1" class="attachment-large size-large" alt="Expenses Claims" loading="lazy" 
												sizes="(max-width: 693px) 100vw, 693px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-56092f42 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="56092f42" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7353e146" data-id="7353e146" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-2ef3693d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2ef3693d" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-33d52bdb" data-id="33d52bdb" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-676f6d72 elementor-invisible elementor-widget elementor-widget-heading" data-id="676f6d72" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h4 class="elementor-heading-title elementor-size-default">Pay Slip Generator</h4>		</div>
				</div>
				<div class="elementor-element elementor-element-7bb6ebdc elementor-widget-divider--view-line elementor-invisible elementor-widget elementor-widget-divider" data-id="7bb6ebdc" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="divider.default">
				<div class="elementor-widget-container">
					<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
		</div>
				</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-47024f1c" data-id="47024f1c" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-77a96406 elementor-widget elementor-widget-text-editor" data-id="77a96406" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
								<div class="elementor-text-editor elementor-clearfix">
				<p>Being a good HR Manager, you frequently give awards and appreciation to employees. But, as a number of employees increase, keeping the track of them becomes difficult. No worries, with HRM you can comfortably manage all the awards, achievements, and appreciations given in your organization.</p>					</div>
						</div>
				</div>
						</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-63dc4009" data-id="63dc4009" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-636af53f elementor-invisible elementor-widget elementor-widget-image" data-id="636af53f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="579" height="297" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/pay-slip90e8.jpg?fit=579%2C297&amp;ssl=1" class="attachment-large size-large" alt="Pay Slip Generator" loading="lazy" 
												sizes="(max-width: 579px) 100vw, 579px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>
    <Footer />
    </div>
  )
}
export default Payroll;