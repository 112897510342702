import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function MCP040(){
    return(
        <div>
            <NavBar/>

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1702 page type-page status-publish ast-article-single" id="post-1702" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1702" class="elementor elementor-1702">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-339b5c5e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="339b5c5e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-38b9e23b" data-id="38b9e23b" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-31190657 elementor-widget elementor-widget-image" data-id="31190657" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="592" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/MCP-040-11bec.jpg?fit=1024%2C592&amp;ssl=1" class="attachment-large size-large" alt="MCP-040-1" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6767d055 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6767d055" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-61e00fd9" data-id="61e00fd9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-631aa8f2 elementor-widget elementor-widget-image" data-id="631aa8f2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1231" height="231" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/MCP-040-2a637.jpg?fit=1231%2C231&amp;ssl=1" class="attachment-full size-full" alt="MCP-040-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-280cda9c elementor-widget elementor-widget-image" data-id="280cda9c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1232" height="497" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/MCP-040-35a41.jpg?fit=1232%2C497&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-443367b1 elementor-widget elementor-widget-image" data-id="443367b1" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1228" height="466" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/MCP-040-466d4.jpg?fit=1228%2C466&amp;ssl=1" class="attachment-full size-full" alt="MCP-040-4" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-142135aa elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="142135aa" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2a80d732" data-id="2a80d732" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-54302242 elementor-widget elementor-widget-image" data-id="54302242" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="508" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/MCP-040-5f3a3.jpg?fit=1024%2C508&amp;ssl=1" class="attachment-large size-large" alt="MCP-040-5" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default MCP040;