import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';
import './about.css'


function About() {
	return (
		<div>
			{/* <NavBar /> */}


			<div id="content" class="site-content">
				<div class="ast-container">


					<div id="primary" class="content-area primary">


						<main id="main" class="site-main">
							<article
								class="post-14 page type-page status-publish ast-article-single" id="post-14" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
								<header class="entry-header ast-header-without-markup">

								</header>

								<div class="entry-content clear"
									itemprop="text"	>


									<div data-elementor-type="wp-post" data-elementor-id="14" class="elementor elementor-14">
										<div class="elementor-inner">
											<div class="elementor-section-wrap">
												<section style={{backgroundColor:'#E6E3E3'}} class="elementor-section elementor-top-section elementor-element elementor-element-438be5a1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="438be5a1" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
													<div class="elementor-container elementor-column-gap-no">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-150 elementor-top-column elementor-element elementor-element-7d758eb" data-id="7d758eb" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap" >
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-8cb8660 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8cb8660" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4cf65221" data-id="4cf65221" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																					<div  class="elementor-column elementor-col-120 elementor-inner-column elementor-element elementor-element-165d7335" data-id="165d7335" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap" >
																								<div class="elementor-element elementor-element-6b73202e elementor-invisible elementor-widget elementor-widget-heading" data-id="6b73202e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h3 class="elementor-heading-title elementor-default" style={{fontWeight:'bold', textAlign:'center'}}>About Us</h3>		</div>
																								</div>
																								<div class="" data-id="2888f3d7" data-element_type="widget" data-widget_type="text-editor.default">
																									<div class="">
																										<div class="" style={{textAlign:'center'}}>
																											Union Community Co. Ltd is a total biometric solution provider with its core technologies evolved from fingerprint recognition.
																											Union Community Co. Ltd has set benchmarks for a user friendly security products including biometric access control systems,
																											time &#038; attendance solutions, fingerprint scanners and modules.

																											<br /><br />

																											Union Community Co. Ltd is exporting their brand, ViRDI to more than 120 countries in worldwide
																											and consistently innovating new biometric solutions for user convenience.					</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3a7cfdc3" data-id="3a7cfdc3" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-0d06763 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0d06763" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row" style={{textAlign:'center'}}>
															<div class="elementor-column elementor-col-120 elementor-top-column elementor-element elementor-element-cf11704" data-id="cf11704" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-bd5bbaa elementor-widget elementor-widget-heading" data-id="bd5bbaa" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h2 class="elementor-heading-title elementor-size-medium">Get biometric time attendance machine price list in Nigeria</h2>		</div>
																		</div>
																		<div class="elementor-element elementor-element-52d93d2 elementor-widget elementor-widget-heading" data-id="52d93d2" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h2 class="elementor-heading-title elementor-size-small">We offer Finger & Facial Thermal Scanner, Facial Recognition & Attendance System</h2>		</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-433f2f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="433f2f5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
													<div class="elementor-container elementor-column-gap-no">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1dae4d2" data-id="1dae4d2" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-7293c9c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7293c9c" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-df8a910" data-id="df8a910" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-120 elementor-inner-column elementor-element elementor-element-46c84f5" data-id="46c84f5" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-d49c66d elementor-invisible elementor-widget elementor-widget-heading" data-id="d49c66d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h3 class="elementor-heading-title elementor-size-default" style={{fontWeight:'bold', textAlign:'center', marginTop:'20px'}}>Brand ViRDI</h3>		</div>
																								</div>
																								<div class="elementor-element elementor-element-3944274 elementor-widget elementor-widget-text-editor" data-id="3944274" data-element_type="widget" data-widget_type="text-editor.default">
																									<div class="elementor-widget-container" style={{textAlign:'center'}}>
																										<div class="elementor-text-editor elementor-clearfix">
																											<p>ViRDI is the world best brand in biometrics for greater safety and convenience.<br />The ViRDI series started in 2000 and has become the most popular brand in face and fingerprint recognition products.<br />ViRDI is pursuing for the paradigm in biometric industry with its market proven expertise and core technology.</p>					</div>
																									</div>
																								</div>
																								<div class="elementor-element elementor-element-c4c4f25 elementor-invisible elementor-widget elementor-widget-image" data-id="c4c4f25" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="image.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-image">
																											<img decoding="async" width="1006" height="417" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/about-img29ff.jpg?fit=1006%2C417&amp;ssl=1" class="attachment-large size-large" alt="Biometric Attendance Machine Price in Nigeria" loading="lazy" 
																											sizes="(max-width: 1006px) 100vw, 1006px" />														</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-78a774f" data-id="78a774f" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-083cdf9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="083cdf9" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a35e0a1" data-id="a35e0a1" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-85abc73 elementor-invisible elementor-widget elementor-widget-image" data-id="85abc73" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="image.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-image">
																					<img decoding="async" width="846" height="374" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/about-img-2874d.jpg?fit=846%2C374&amp;ssl=1" class="attachment-large size-large" alt="Biometric Time Attendance System in Nigeria" loading="lazy" 
																					sizes="(max-width: 846px) 100vw, 846px" />														</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-fb9ed82 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fb9ed82" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
													<div class="elementor-container elementor-column-gap-no">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8fc2204" data-id="8fc2204" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-6485968 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6485968" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f465fc7" data-id="f465fc7" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4f6d4a8" data-id="4f6d4a8" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap" style={{textAlign:'center'}}>
																								<div class="elementor-element elementor-element-02c1814 elementor-invisible elementor-widget elementor-widget-heading" data-id="02c1814" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h2 class="elementor-heading-title elementor-size-default">Mission</h2>		</div>
																								</div>
																								<div class="elementor-element elementor-element-f83fdc3 elementor-widget elementor-widget-text-editor" data-id="f83fdc3" data-element_type="widget" data-widget_type="text-editor.default">
																									<div class="elementor-widget-container">
																										<div class="elementor-text-editor elementor-clearfix">
																											<p>Make Life better by Innovating Biometric Solutions for our Customers!</p>					</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3eec0e3" data-id="3eec0e3" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section style={{backgroundColor:'#E6E3E3'}} class="elementor-section elementor-top-section elementor-element elementor-element-249af26 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="249af26" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row" >
															<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-0d35d2f" data-id="0d35d2f" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-344b574 elementor-widget elementor-widget-html" data-id="344b574" data-element_type="widget" data-widget_type="html.default">
																			<div class="elementor-widget-container">





																				<div class="flip-box">
																					<div class="flip-box-inner">
																						<div class="flip-box-front">
																							<h6 style={{
																								marginTop: '25%'
																							}}>Advanced Technology Provider</h6>
																						</div>
																						<div class="flip-box-back">
																							<h6 style={{
																								marginTop: '20%', color: 'white'
																							}}>Advanced Technology Provider</h6>
																							<p>Provide Enhanced Biometric Technology</p>
																						</div>
																					</div>
																				</div>		</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1434e89" data-id="1434e89" data-element_type="column">
																<div class="elementor-column-wrap">
																	<div class="elementor-widget-wrap">
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-7d9eec9" data-id="7d9eec9" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-4b03dfe elementor-widget elementor-widget-html" data-id="4b03dfe" data-element_type="widget" data-widget_type="html.default">
																			<div class="elementor-widget-container">





																				<div class="flip-box">
																					<div class="flip-box-inner">
																						<div class="flip-box-front">
																							<h6 style={{
																								marginTop: '25%'
																							}}>Professional Consultant</h6>
																						</div>
																						<div class="flip-box-back">
																							<h6 style={{
																								marginTop: '20%', color: 'white'
																							}}>Professional Consultant</h6>
																							<p>Provide the Best Biometrics Solution for Convenience and Security</p>
																						</div>
																					</div>
																				</div>		</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-193bcbe" data-id="193bcbe" data-element_type="column">
																<div class="elementor-column-wrap">
																	<div class="elementor-widget-wrap">
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-4d84ba9" data-id="4d84ba9" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-89288d5 elementor-widget elementor-widget-html" data-id="89288d5" data-element_type="widget" data-widget_type="html.default">
																			<div class="elementor-widget-container">




																				<div class="flip-box">
																					<div class="flip-box-inner">
																						<div class="flip-box-front">
																							<h6 style={{
																								marginTop: '25%'
																							}}>Professional Consultant</h6>
																						</div>
																						<div class="flip-box-back">
																							<h6 style={{
																								marginTop: '20%', color: 'white'
																							}}>Professional Consultant</h6>
																							<p>Provide the Best Biometrics Solution for Convenience and Security</p>
																						</div>
																					</div>
																				</div>		</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-8571e02 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8571e02" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
													<div class="elementor-container elementor-column-gap-no">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2370d24" data-id="2370d24" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<section class="elementor-section elementor-inner-section elementor-element elementor-element-98cd210 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="98cd210" data-element_type="section">
																			<div class="elementor-container elementor-column-gap-default">
																				<div class="elementor-row">
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-9c80743" data-id="9c80743" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-82f5338" data-id="82f5338" data-element_type="column">
																						<div class="elementor-column-wrap elementor-element-populated">
																							<div class="elementor-widget-wrap">
																								<div class="elementor-element elementor-element-d2813b7 elementor-invisible elementor-widget elementor-widget-heading" data-id="d2813b7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}" data-widget_type="heading.default">
																									<div class="elementor-widget-container">
																										<h2 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}>Values</h2>		</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-56f9f7a" data-id="56f9f7a" data-element_type="column">
																						<div class="elementor-column-wrap">
																							<div class="elementor-widget-wrap">
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																		<div class="elementor-element elementor-element-dc7cdfe elementor-widget elementor-widget-image" data-id="dc7cdfe" data-element_type="widget" data-widget_type="image.default">
																			<div class="elementor-widget-container">
																				<div class="elementor-image">
																					<img decoding="async" width="978" height="513" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/05/valueseff5.jpg?fit=978%2C513&amp;ssl=1" class="attachment-large size-large" alt="Virdi Solutions" loading="lazy" 
																					sizes="(max-width: 978px) 100vw, 978px" />														</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-0197566 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0197566" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-203e0d9" data-id="203e0d9" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-028c001 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="028c001" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="fas fa-users"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Customer-Focused</h6> <br />Satisfy and Impress Customers through insightful understanding of their needs</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-afd1249" data-id="afd1249" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-bfb2899 elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="bfb2899" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items elementor-inline-items">
																					<li class="elementor-icon-list-item elementor-inline-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="fas fa-globe-americas"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Global Talent</h6>
																							<br />Nature all employees into globally competitive talents with open mind</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b6e7a5a" data-id="b6e7a5a" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-3bc2981 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="3bc2981" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="fas fa-trophy"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Ownership</h6>
																							<br />Take a Responsibility at work for growth</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-4ba02e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4ba02e0" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-b9f39e0" data-id="b9f39e0" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-00108ae elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="00108ae" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="fab fa-gripfire"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Passion</h6> <br />Power to fulfillthe goal by overcoming obstacles</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-58a446a" data-id="58a446a" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-bfe4549 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="bfe4549" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="fas fa-rocket"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Challenge</h6> <br />Take the initiative and proactive attitude by exploring new business opportunities</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-89eb966" data-id="89eb966" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-55b2bb9 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="55b2bb9" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="far fa-comments"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Communication</h6> <br />Respect for diversity and Achieve synergy by making a superb teamwork through active communication</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-87a556a" data-id="87a556a" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-983d2c6 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="983d2c6" data-element_type="widget" data-widget_type="icon-list.default">
																			<div class="elementor-widget-container">
																				<ul class="elementor-icon-list-items">
																					<li class="elementor-icon-list-item">
																						<span class="elementor-icon-list-icon">
																							<i aria-hidden="true" class="far fa-lightbulb"></i>						</span>
																						<span class="elementor-icon-list-text"><h6 style={{
																							marginBottom: '0',
																							fontFamily: 'Archivo Black',
																							fontSize: '22px'
																						}}>Innovation</h6> <br />Creative thinking based on professional mind to create new values</span>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>



								</div>



							</article>

						</main>


					</div>


				</div>
			</div>

			<Footer />
		</div>
	)
}
export default About;