import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Tablet5(){
    return(
        <div>
            <NavBar/>

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1728 page type-page status-publish ast-article-single" id="post-1728" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1728" class="elementor elementor-1728">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-4d364e9f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4d364e9f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2241a7b3" data-id="2241a7b3" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-79fee383 elementor-widget elementor-widget-image" data-id="79fee383" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="901" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-18f82.jpg?fit=1024%2C901&amp;ssl=1" class="attachment-large size-large" alt="Ubio-Tablet-5-1" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-2653824a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2653824a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f109d40" data-id="2f109d40" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-2a4f587d elementor-widget elementor-widget-image" data-id="2a4f587d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="868" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-2202e.jpg?fit=1237%2C868&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-18c50f16 elementor-widget elementor-widget-image" data-id="18c50f16" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1242" height="625" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-38070.jpg?fit=1242%2C625&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-41ccafff elementor-widget elementor-widget-image" data-id="41ccafff" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1239" height="626" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-3-13ffa.jpg?fit=1239%2C626&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-767b409d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="767b409d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-13f2e60e" data-id="13f2e60e" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-275ae657 elementor-widget elementor-widget-image" data-id="275ae657" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="320" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-49c58.jpg?fit=1024%2C320&amp;ssl=1" class="attachment-large size-large" alt="UBIO-TABLET-5-4" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-5458c6bf elementor-widget elementor-widget-image" data-id="5458c6bf" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="685" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-59b92.jpg?fit=1024%2C685&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-69f7f69c elementor-widget elementor-widget-image" data-id="69f7f69c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="626" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-6267c.jpg?fit=1024%2C626&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-5e8b5890 elementor-widget elementor-widget-image" data-id="5e8b5890" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="301" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-78e5f.jpg?fit=1024%2C301&amp;ssl=1" class="attachment-large size-large" alt="ubio-tablet-5-7" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-4c2adbb2 elementor-widget elementor-widget-image" data-id="4c2adbb2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="678" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-8be7d.jpg?fit=1024%2C678&amp;ssl=1" class="attachment-large size-large" alt="UBIO-TABLET-5-8" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7e872f2d elementor-widget elementor-widget-image" data-id="7e872f2d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="504" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-940a0.jpg?fit=1024%2C504&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-4769d5f2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4769d5f2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-68eaa0f6" data-id="68eaa0f6" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-6a3ec1fa elementor-widget elementor-widget-image" data-id="6a3ec1fa" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="694" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-tablet-5-10f29e.jpg?fit=1024%2C694&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default Tablet5;