import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Pro2(){
    return(
        <div>
            <NavBar/>


            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1581 page type-page status-publish ast-article-single" id="post-1581" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1581" class="elementor elementor-1581">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-2eb2533 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2eb2533" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dc59f31" data-id="dc59f31" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-881e12b elementor-widget elementor-widget-image" data-id="881e12b" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="623" 
							src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-x-pro-MAIN3d5c.jpg?fit=1024%2C623&amp;ssl=1" class="attachment-large size-large" alt="Ubio-X-Pro-Main" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6880483 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6880483" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-808b1d6" data-id="808b1d6" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-fa5322c elementor-widget elementor-widget-image" data-id="fa5322c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1241" height="422" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-159d0.jpg?fit=1241%2C422&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-f9e19ed elementor-widget elementor-widget-image" data-id="f9e19ed" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1240" height="762" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-2a5e7.jpg?fit=1240%2C762&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-d004316 elementor-widget elementor-widget-image" data-id="d004316" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1238" height="801" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-386c5.jpg?fit=1238%2C801&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-5b12fa2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5b12fa2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d986e07" data-id="d986e07" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-6bc10b6 elementor-widget elementor-widget-image" data-id="6bc10b6" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="205" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-46806.jpg?fit=1024%2C205&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-4112920 elementor-widget elementor-widget-image" data-id="4112920" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="409" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-5f345.jpg?fit=1024%2C409&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-64a3856 elementor-widget elementor-widget-image" data-id="64a3856" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="443" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-68840.jpg?fit=1024%2C443&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-0b8b547 elementor-widget elementor-widget-image" data-id="0b8b547" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="610" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-710b1.jpg?fit=1024%2C610&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-c26d633 elementor-widget elementor-widget-image" data-id="c26d633" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="723" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-88e90.jpg?fit=1024%2C723&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7707424 elementor-widget elementor-widget-image" data-id="7707424" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="652" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ub-90f53.jpg?fit=1024%2C652&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-435a088 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="435a088" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f56be7" data-id="9f56be7" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-15d7dc6 elementor-widget elementor-widget-image" data-id="15d7dc6" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="649" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-x-pro-MAIN-3f670.jpg?fit=1024%2C649&amp;ssl=1" class="attachment-large size-large" alt="Ubio X Pro in Nigeria" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>


            <Footer/>
        </div>
    )
}

export default Pro2;