import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function ProLite(){
    return(
        <div>
            <NavBar/>

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1617 page type-page status-publish ast-article-single" id="post-1617" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1617" class="elementor elementor-1617">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-5c2df74a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5c2df74a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5274bf5d" data-id="5274bf5d" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-7c989cb2 elementor-widget elementor-widget-image" data-id="7c989cb2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro Lite.pdf">
							<img decoding="async" width="1024" height="726" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/ubio-X-pro-LITE61b9.jpg?fit=1024%2C726&amp;ssl=1" class="attachment-large size-large" alt="UBio-X Pro Lite" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-267c9ee0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="267c9ee0" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-73236662" data-id="73236662" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-5baefe4e elementor-widget elementor-widget-image" data-id="5baefe4e" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1241" height="843" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-1a508.jpg?fit=1241%2C843&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-4c3f003e elementor-widget elementor-widget-image" data-id="4c3f003e" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1238" height="560" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-215b4.jpg?fit=1238%2C560&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-d87de7d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d87de7d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b5f432d" data-id="4b5f432d" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-66d844df elementor-widget elementor-widget-image" data-id="66d844df" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="628" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-35257.jpg?fit=1024%2C628&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-7ea6fc35 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7ea6fc35" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-41dc7f9e" data-id="41dc7f9e" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-209a360b elementor-widget elementor-widget-image" data-id="209a360b" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="584" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-4a890.jpg?fit=1024%2C584&amp;ssl=1" class="attachment-large size-large" alt="LITE-4 in Nigeria" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6967ba7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6967ba7" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-172ceed" data-id="172ceed" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-e5979bc elementor-widget elementor-widget-image" data-id="e5979bc" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="717" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-58eb9.jpg?fit=1024%2C717&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-203b70d elementor-widget elementor-widget-image" data-id="203b70d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="675" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-69e31.jpg?fit=1024%2C675&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-8190e43 elementor-widget elementor-widget-image" data-id="8190e43" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="706" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/LITE-73012.jpg?fit=1024%2C706&amp;ssl=1" 
												class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			
			
		</main>
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default ProLite;