import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function Unis() {
  return (
    <div>
      <NavBar />

      <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1790 page type-page status-publish ast-article-single" id="post-1790" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1790" class="elementor elementor-1790">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-3df1a1f4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3df1a1f4" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5852bc74" data-id="5852bc74" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-5c7b3980 elementor-widget elementor-widget-image" data-id="5c7b3980" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="264" 
							src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-1a6b2.jpg?fit=1024%2C264&amp;ssl=1" class="attachment-large size-large" alt="UNIS-1" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-112d9a75 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="112d9a75" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1ee38475" data-id="1ee38475" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1c2470f2 elementor-widget elementor-widget-image" data-id="1c2470f2" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1238" height="685" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-25a90.jpg?fit=1238%2C685&amp;ssl=1" class="attachment-full size-full" alt="UNIS-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-324dab1f elementor-widget elementor-widget-image" data-id="324dab1f" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1228" height="649" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-37f40.jpg?fit=1228%2C649&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7c99a27d elementor-widget elementor-widget-image" data-id="7c99a27d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1231" height="744" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-4cc95.jpg?fit=1231%2C744&amp;ssl=1" class="attachment-full size-full" alt="UNIS-4" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-670390ea elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="670390ea" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3b2a423f" data-id="3b2a423f" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-2f213783 elementor-widget elementor-widget-image" data-id="2f213783" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="713" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-50f33.jpg?fit=1024%2C713&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-274c89c6 elementor-widget elementor-widget-image" data-id="274c89c6" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="424" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-6-1d853.jpg?fit=1024%2C424&amp;ssl=1" class="attachment-large size-large" alt="UNIS-6" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-13ac9a0b elementor-widget elementor-widget-image" data-id="13ac9a0b" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="262" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-7c031.jpg?fit=1024%2C262&amp;ssl=1" class="attachment-large size-large" alt="UNIS-7" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-5ebef4bb elementor-widget elementor-widget-image" data-id="5ebef4bb" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="634" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-8443f.jpg?fit=1024%2C634&amp;ssl=1" class="attachment-large size-large" alt="UNIS-8" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7685d29b elementor-widget elementor-widget-image" data-id="7685d29b" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="699" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-96497.jpg?fit=1024%2C699&amp;ssl=1" class="attachment-large size-large" alt="UNIS-9" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-a74fa03 elementor-widget elementor-widget-image" data-id="a74fa03" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="669" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-10ccf0.jpg?fit=1024%2C669&amp;ssl=1" class="attachment-large size-large" alt="UNIS-10" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-238eb3b6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="238eb3b6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5ccfdf08" data-id="5ccfdf08" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-3cd77467 elementor-widget elementor-widget-image" data-id="3cd77467" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="310" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-116280.jpg?fit=1024%2C310&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-a656fce elementor-widget elementor-widget-image" data-id="a656fce" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="715" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-120ca1.jpg?fit=1024%2C715&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-4e0f0b8 elementor-widget elementor-widget-image" data-id="4e0f0b8" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="344" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-130834.jpg?fit=1024%2C344&amp;ssl=1" class="attachment-large size-large" alt="UNIS-13" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-6622b21 elementor-widget elementor-widget-image" data-id="6622b21" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" src="assets/i0.wp.com/www.eggle.biz/wp-content/uploads/2020/06/UNIS-14-1aeea.html?w=1200&amp;ssl=1" title="" alt="" data-recalc-dims="1" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7cc0b7b elementor-widget elementor-widget-image" data-id="7cc0b7b" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="647" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-15-13cac.jpg?fit=1024%2C647&amp;ssl=1" class="attachment-large size-large" alt="UNIS-15" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-66f3a23 elementor-widget elementor-widget-image" data-id="66f3a23" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="396" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UNIS-169496.jpg?fit=1024%2C396&amp;ssl=1" class="attachment-large size-large" alt="UNIS-16" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

    <Footer />
    </div>
  )
}
export default Unis;