import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function R20D(){
    return(
        <div>
            <NavBar/>

            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1688 page type-page status-publish ast-article-single" id="post-1688" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1688" class="elementor elementor-1688">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-20a4c00b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="20a4c00b" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4562466" data-id="4562466" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-5f0477a4 elementor-widget elementor-widget-image" data-id="5f0477a4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="563" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-111b9.jpg?fit=1024%2C563&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-5eea2e52 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5eea2e52" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-219a4071" data-id="219a4071" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-1704bcd0 elementor-widget elementor-widget-image" data-id="1704bcd0" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1240" height="514" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-243ac.jpg?fit=1240%2C514&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-231a9525 elementor-widget elementor-widget-image" data-id="231a9525" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="554" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-36e18.jpg?fit=1237%2C554&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-4ba84ebc elementor-widget elementor-widget-image" data-id="4ba84ebc" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="492" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-4c995.jpg?fit=1237%2C492&amp;ssl=1" class="attachment-full size-full" alt="VS-R20D-4" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-1989ec4a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1989ec4a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f6d90cb" data-id="6f6d90cb" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-71d0c266 elementor-widget elementor-widget-image" data-id="71d0c266" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="379" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-56576.jpg?fit=1024%2C379&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-35cd222e elementor-widget elementor-widget-image" data-id="35cd222e" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="580" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/VS-R20D-63178.jpg?fit=1024%2C580&amp;ssl=1" class="attachment-large size-large" alt="VS-R20D-6" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

            <Footer/>
        </div>
    )
}

export default R20D;