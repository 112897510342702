import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function FOH02(){
    return(
        <div>
            <NavBar/>

            
            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1712 page type-page status-publish ast-article-single" id="post-1712" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1712" class="elementor elementor-1712">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-21d59a78 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="21d59a78" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-285508b5" data-id="285508b5" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-4202df7e elementor-widget elementor-widget-image" data-id="4202df7e" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="647" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02_en13cac.jpg?fit=1024%2C647&amp;ssl=1" class="attachment-large size-large" alt="FOH02" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-505f0d05 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="505f0d05" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-754fc6d7" data-id="754fc6d7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-51b500b0 elementor-widget elementor-widget-image" data-id="51b500b0" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1240" height="448" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02-2af7a.jpg?fit=1240%2C448&amp;ssl=1" class="attachment-full size-full" alt="FOH02-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-6a5a5cdd elementor-widget elementor-widget-image" data-id="6a5a5cdd" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="1138" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02-36efd.jpg?fit=1237%2C1138&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-2bec25cc elementor-widget elementor-widget-image" data-id="2bec25cc" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1241" height="1082" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02-43742.jpg?fit=1241%2C1082&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-4f61d9ea elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4f61d9ea" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3fe8c7f6" data-id="3fe8c7f6" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-30b6b20d elementor-widget elementor-widget-image" data-id="30b6b20d" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="722" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02-5d796.jpg?fit=1024%2C722&amp;ssl=1" class="attachment-large size-large" alt="FoH02-5" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-3edc7f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3edc7f7" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b0f1d03" data-id="b0f1d03" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-3644312 elementor-widget elementor-widget-image" data-id="3644312" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="489" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/foh02-7a972.jpg?fit=1024%2C489&amp;ssl=1" class="attachment-large size-large" alt="FoH02-7" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>
            <Footer/>
        </div>
    )
}

export default FOH02;