import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function Contact() {
  return (
    <div>
      <NavBar />

    <Footer />
    </div>
  )
}
export default Contact;