import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function Alpeta() {
  return (
    <div>
      {/* <NavBar /> */}


      <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1763 page type-page status-publish ast-article-single" id="post-1763" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1763" class="elementor elementor-1763">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-25ffc96f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="25ffc96f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67c8669e" data-id="67c8669e" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-7a907a8a elementor-widget elementor-widget-image" data-id="7a907a8a" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="402" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-164a0.jpg?fit=1024%2C402&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-1" loading="lazy" 
							
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-48f165d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="48f165d2" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c70366b" data-id="c70366b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-47300ff7 elementor-widget elementor-widget-image" data-id="47300ff7" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1238" height="764" 
												src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-20870.jpg?fit=1238%2C764&amp;ssl=1" class="attachment-full size-full" alt="UBio Alpeta-2" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-660a5f60 elementor-widget elementor-widget-image" data-id="660a5f60" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1241" height="657" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-34ccf.jpg?fit=1241%2C657&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-22bcdbf9 elementor-widget elementor-widget-image" data-id="22bcdbf9" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1240" height="793" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-42caf.jpg?fit=1240%2C793&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-6b8ba6c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6b8ba6c6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-47d11c52" data-id="47d11c52" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-109691a4 elementor-widget elementor-widget-image" data-id="109691a4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="726" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-561b9.jpg?fit=1024%2C726&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-5" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-3c9a5329 elementor-widget elementor-widget-image" data-id="3c9a5329" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="710" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-66a26.jpg?fit=1024%2C710&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-6" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-7ba418a4 elementor-widget elementor-widget-image" data-id="7ba418a4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="624" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-714bc.jpg?fit=1024%2C624&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-41326d2a elementor-widget elementor-widget-image" data-id="41326d2a" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="586" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-862b0.jpg?fit=1024%2C586&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-2cad88b7 elementor-widget elementor-widget-image" data-id="2cad88b7" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="169" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-9105b.jpg?fit=1024%2C169&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-9" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-5df3d85c elementor-widget elementor-widget-image" data-id="5df3d85c" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="639" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-107e4f.jpg?fit=1024%2C639&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-5f844ca elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5f844ca" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5092f783" data-id="5092f783" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-43344672 elementor-widget elementor-widget-image" data-id="43344672" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" src="assets/i0.wp.com/www.eggle.biz/wp-content/uploads/2020/06/UBio-Alpeta-11aeea.html?w=1200&amp;ssl=1" title="" alt="" data-recalc-dims="1" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-81e883f elementor-widget elementor-widget-image" data-id="81e883f" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" src="assets/i0.wp.com/www.eggle.biz/wp-content/uploads/2020/06/UBio-Alpeta-11aeea.html?w=1200&amp;ssl=1" title="" alt="" data-recalc-dims="1" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-8ea91b4 elementor-widget elementor-widget-image" data-id="8ea91b4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="646" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-12-1c90b.jpg?fit=1024%2C646&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-12" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-fd5b897 elementor-widget elementor-widget-image" data-id="fd5b897" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="715" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-130ca1.jpg?fit=1024%2C715&amp;ssl=1" class="attachment-large size-large" alt="UBio Alpeta-13" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-98424ab elementor-widget elementor-widget-image" data-id="98424ab" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="319" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/UBio-Alpeta-14ccfb.jpg?fit=1024%2C319&amp;ssl=1" class="attachment-large size-large" alt="" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>

    <Footer />
    </div>
  )
}
export default Alpeta;