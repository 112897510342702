import Footer from "../partial/footer";
import NavBar from "../partial/navbar";

function Plus2100(){
    return(
        <div>
            <NavBar/>

            
            <div id="content" class="site-content">
		<div class="ast-container">
		

	<div id="primary" class="content-area primary">

		
					<main id="main" class="site-main">
				<article
class="post-1666 page type-page status-publish ast-article-single" id="post-1666" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
		<header class="entry-header ast-header-without-markup">
		
			</header>

	<div class="entry-content clear"
		itemprop="text"	>

		
				<div data-elementor-type="wp-page" data-elementor-id="1666" class="elementor elementor-1666">
						<div class="elementor-inner">
				<div class="elementor-section-wrap">
									<section class="elementor-section elementor-top-section elementor-element elementor-element-4ec6268a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4ec6268a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-28ffd02f" data-id="28ffd02f" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-4ff08d42 elementor-widget elementor-widget-image" data-id="4ff08d42" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
													<a href="https://www.virditech.com/doc/2019/UBio-X Pro 2.pdf">
							<img decoding="async" width="1024" height="691" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-2100PLUS-1574a.jpg?fit=1024%2C691&amp;ssl=1" 
							class="attachment-large size-large" alt="AC-2100 PLUS in Nigeria by Eggle Search International" loading="lazy" 
							sizes="(max-width: 1024px) 100vw, 1024px" />								</a>
														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-69ecfb70 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="69ecfb70" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67fc882b" data-id="67fc882b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-4c3e44de elementor-widget elementor-widget-image" data-id="4c3e44de" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1239" height="511" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-2100PLUS-21703.jpg?fit=1239%2C511&amp;ssl=1" class="attachment-full size-full" alt="AC-2100PLUS Features in Nigeria" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-1193f919 elementor-widget elementor-widget-image" data-id="1193f919" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1237" height="597" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-2100PLUS-3d8f8.jpg?fit=1237%2C597&amp;ssl=1" class="attachment-full size-full" alt="" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
				<div class="elementor-element elementor-element-683d5eab elementor-widget elementor-widget-image" data-id="683d5eab" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1235" height="524" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-2100PLUS-4abfe.jpg?fit=1235%2C524&amp;ssl=1" class="attachment-full size-full" alt="AC-2100PLUS Wider Screen Feature" loading="lazy" 
												sizes="(max-width: 1200px) 100vw, 1200px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-e29e132 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e29e132" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
							<div class="elementor-row">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6379b9bf" data-id="6379b9bf" data-element_type="column">
			<div class="elementor-column-wrap elementor-element-populated">
							<div class="elementor-widget-wrap">
						<div class="elementor-element elementor-element-280c6fc9 elementor-widget elementor-widget-image" data-id="280c6fc9" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
								<div class="elementor-image">
												<img decoding="async" width="1024" height="692" src="assets/i0.wp.com/www.virdi.biz/wp-content/uploads/2020/06/AC-2100PLUS-5_specsde5a.jpg?fit=1024%2C692&amp;ssl=1" class="attachment-large size-large" alt="AC-2100PLUS-5 Specifications" loading="lazy" 
												sizes="(max-width: 1024px) 100vw, 1024px" />														</div>
						</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
									</div>
			</div>
					</div>
		
		
		
	</div>

	
	
</article>

			</main>
			
		
	</div>


	</div> 
	</div>
            <Footer/>
        </div>
    )
}

export default Plus2100;