import React from 'react'
import NavBar from '../partial/navbar';
import Footer from '../partial/footer';


function Associtae() {
	return (
		<div>
			{/* <NavBar /> */}

			<div id="content" class="site-content">
				<div class="ast-container">


					<div id="primary" class="content-area primary">


						<main id="main" class="site-main">
							<article
								class="post-2851 page type-page status-publish ast-article-single" id="post-2851" itemtype="https://schema.org/CreativeWork" itemscope="itemscope">
								<header class="entry-header ast-header-without-markup">

								</header>

								<div class="entry-content clear"
									itemprop="text"	>


									<div data-elementor-type="wp-page" data-elementor-id="2851" class="elementor elementor-2851">
										<div class="elementor-inner">
											<div class="elementor-section-wrap">
												<section class="elementor-section elementor-top-section elementor-element elementor-element-ed3928c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ed3928c" data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9629efa" data-id="9629efa" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-95c3f05 elementor-widget elementor-widget-heading" data-id="95c3f05" data-element_type="widget" data-widget_type="heading.default">
																			<div class="elementor-widget-container">
																				<h2 class="elementor-heading-title elementor-size-default" style={{textAlign:'center'}}>Associate With Us</h2>		</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<section class="elementor-section elementor-top-section elementor-element elementor-element-7f4a765 
                								elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7f4a765"
													data-element_type="section">
													<div class="elementor-container elementor-column-gap-default">
														<div class="elementor-row">
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-c1d58e5"
																data-id="c1d58e5" data-element_type="column">
																<div class="elementor-column-wrap">
																	<div class="elementor-widget-wrap">
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ab9b502" data-id="ab9b502" data-element_type="column">
																<div class="elementor-column-wrap elementor-element-populated">
																	<div class="elementor-widget-wrap">
																		<div class="elementor-element elementor-element-6fee585 elementor-widget elementor-widget-wpforms" data-id="6fee585" data-element_type="widget" data-widget_type="wpforms.default">
																			<div class="elementor-widget-container">
																				<div class="wpforms-container " id="wpforms-2854">
																					<form class="wpforms-validate wpforms-form"  method="post" enctype="multipart/form-data"
																						action="mailto:info@virdi.biz">
																						
																						<div class="wpforms-field-container">
																							<div id="wpforms-2854-field_0-container" class="wpforms-field wpforms-field-name" data-field-id="0">
																								<label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_0">
																									Name <span class="wpforms-required-label">*</span></label>
																								<input type="text" id="wpforms-2854-field_0" class="wpforms-field-large wpforms-field-required" name="wpforms[fields][0]" placeholder="Your Name" required />
																							</div>
																							<div id="" class="wpforms-field wpforms-field-email" data-field-id="1">
																								<label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_1">Email <span class="wpforms-required-label">*</span></label>
																								<input type="email" id="" class="wpforms-field-large wpforms-field-required" name="email" placeholder="Email" required /></div>
																							<div id="wpforms-2854-field_4-container" class="wpforms-field wpforms-field-number" data-field-id="4">
																								<label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_4">Contact No. <span class="wpforms-required-label">*</span></label>
																								<input type="number" pattern="*" id="" class="wpforms-field-large wpforms-field-required" name="contact" placeholder="Contact No." required /></div>
																							<div id="" class="wpforms-field wpforms-field-number" >
																								<label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_9">City
																									<span class="wpforms-required-label">*</span></label>
																								<input type="text" id="" class="wpforms-field-large wpforms-field-required" name="city" placeholder="City" required /></div>
																							<div id="wpforms-2854-field_2-container" class="wpforms-field wpforms-field-textarea"
																								data-field-id="2"><label class="wpforms-field-label wpforms-label-hide" for="wpforms-2854-field_2">
																									Message <span class="wpforms-required-label">*</span></label><textarea id="wpforms-2854-field_2"
																										class="wpforms-field-large wpforms-field-required" name="message" placeholder="Message" required></textarea>
																							</div></div>
																						<div class="wpforms-submit-container">
																							<button type="submit" name="wpforms[submit]"
																								id="wpforms-submit-2854" class="wpforms-submit" data-alt-text="Sending..." data-submit-text="Send" aria-live="assertive" value="wpforms-submit">Send
																							</button></div></form></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-db2d576" data-id="db2d576" data-element_type="column">
																<div class="elementor-column-wrap">
																	<div class="elementor-widget-wrap">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>



								</div>



							</article>

						</main>


					</div>


				</div>
			</div>

			<Footer />
		</div>
	)
}
export default Associtae;